<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="viewAllModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered custom-modal-width">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="viewAllModalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body custom-modal-body">
                    <div class="row">
                        <div v-for="book in books" :key="book.id" class="col-6 col-md-4 col-lg-3 mb-3">
                            <div class="book-item">
                                <div class="card modal-book-card">
                                    <img :src="book.book_cover ? storage + book.book_cover : defaultBook"
                                        alt="Book Cover" class="img-fluid rounded">
                                    <div class="overlay">
                                        <div class="overlay-content">
                                            <h5 class="overlay-title text-white"><b>{{ book.book_title }}</b>
                                            </h5>
                                            <p class="overlay-author text-white">{{ book.author }}</p>
                                            <button class="btn btn-primary" @click="openDetailModal(book)">More
                                                Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>


</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import storage from '@/constants'
import defaultBook from '@/assets/images/coverless-book.jpg'

const props = defineProps({
    modalId: {
        type: String,
        require: true
    },
    books: {
        type: Array,
        require: true
    },
    title: {
        type: String,
        require: true
    }
})

const emit = defineEmits(['open-detail-modal', 'close-books-modal'])

const openDetailModal = (book) => {
    emit('open-detail-modal', book);
    const modal = bootstrap.Modal.getInstance(document.getElementById(props.modalId));
    if (modal) {
        modal.hide();
    }
};

</script>

<style scoped>
.btn-primary {
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.custom-modal-body {
    max-height: 80vh;
    overflow-y: auto;
    background-color: #110f0d;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  border: none;
  overflow: hidden;
  border-radius: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease-in-out;
}

.card-img-top {
  height: 400px;
  border-bottom: 2px solid #f1c503;
}

.card-img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card.book-card {
  position: relative;
  overflow: hidden;
}

.card.book-card img {
  transition: transform 0.3s ease;
}

.card.book-card:hover img {
  transform: scale(1.1);
}


/* Modal Book Card Styles */
.card.modal-book-card {
    position: relative;
    overflow: hidden;
    height: 100%;
    /* Ensure the card fits within the modal */
}

.card.modal-book-card img {
    transition: transform 0.3s ease;
    height: 100%;
    /* Ensure the image fits within the card */
    object-fit: cover;
    /* Maintain aspect ratio */
}

.card.modal-book-card:hover img {
    transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(4px);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 12px;
}


.overlay-content {
  text-align: center;
}

.overlay-title,
.overlay-author {
  margin: 0;
}

.modal-book-card .overlay {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(4px);
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 12px;
}

.modal-book-card:hover .overlay {
    top: 0;
    opacity: 1;
}

.modal-book-card .overlay-content {
    text-align: center;
}

.modal-book-card .overlay-title,
.modal-book-card .overlay-author {
    margin: 0;
}

.custom-modal-width {
    max-width: 1200px;
}

.card.book-card:hover .overlay {
  top: 0;
  opacity: 1;
}

.modal-content {
    text-align: center;
    color: #fff;
    background-color: rgba(28, 28, 28, 0.8);
    backdrop-filter: blur(10px);
    border: none;
    overflow: hidden;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
}
</style>

