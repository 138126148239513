<template>
  <div class="search-bar-container">
    <div class="search-bar">
      <input type="text" class="form-control" :placeholder="placeholder" v-model="searchQuery">
      <button class="btn" type="button" @click="onSearch">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Search..."
    }
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchQuery);
    }
  }
};

</script>

<style scoped>
.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  max-width: 440px;
  width: 100%;
}

.form-control {
  flex-grow: 1;
}

.btn {
  margin-left: 10px;
}
</style>