<template>
  <div class="books-grid py-5">
    <div class="container">
      <div class="carousel-header">
        <h2 class="section-title mb-4">{{ props.title }}</h2>
        <!-- <button class="btn btn-view-all" @click="openViewAllModal">View All</button> -->
        <button class="btn btn-view-all" @click="openViewAllModal">View All</button>
      </div>
      <div id="booksCarousel" class="carousel slide">
        <div class="carousel-inner">
          <div v-for="(chunk, index) in bookChunks" :key="index" :class="['carousel-item', { active: index === 0 }]">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3 mb-4" v-for="book in chunk" :key="book.id">
                <div class="card book-card">
                  <img :src="book.book_cover ? storage + book.book_cover : defaultBook"
                  :alt="book.book_title" class="card-img-top">
                  <div class="overlay">
                    <div class="overlay-content">
                      <h5 class="overlay-title text-white"><b>{{ book.book_title }}</b></h5>
                      <p class="overlay-author text-white">{{ book.author }}</p>
                      <button class="btn btn-primary" @click="openDetailModal(book)">More Details</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <button class="carousel-control-prev" type="button" data-bs-target="#booksCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#booksCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>

      <ViewAllModal :modalId="modalId" :books="books" :title="title" @open-detail-modal="openDetailModal"/>
    </div>
  </div>
</template>


<script setup>
import { computed, ref } from 'vue';
import storage from '@/constants';
import ViewAllModal from '@/components/eBooks/ViewAllModal.vue';
import defaultBook from '@/assets/images/coverless-book.jpg';

const props = defineProps({
  books: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  modalId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['open-detail-modal', 'open-view-modal', 'close-books-modal']);

// const bookChunks = computed(() => {
//   const chunkSize = 4;
//   const chunks = [];
//   for (let i = 0; i < props.books.length; i += chunkSize) {
//     chunks.push(props.books.slice(i, i + chunkSize));
//   }
//   return chunks;
// });

const bookChunks = computed(() => {
  const chunkSize = 4;
  return props.books.reduce((chunks, book, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!chunks[chunkIndex]) {
      chunks[chunkIndex] = [];
    }
    chunks[chunkIndex].push(book);
    return chunks;
  }, []);
});

const openDetailModal = (book) => {
  emit('open-detail-modal', book);
};

const openViewAllModal = () => {
  const modal = new bootstrap.Modal(document.getElementById(props.modalId));
  modal.show();
  //emit('open-view-modal', props.modalId);
};


</script>


<style scoped>
.carousel-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  padding-bottom: 40px;
  color: #f1c503;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Nunito", sans-serif;
}

.btn-view-all {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 40px;
}

.btn-view-all:hover {
  background-color: #110f0d;
  color: #f1c503;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  border: none;
  overflow: hidden;
  border-radius: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease-in-out;
}

.card-img-top {
  height: 400px;
  border-bottom: 2px solid #f1c503;
}

.card-img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rating {
  display: flex;
  gap: 2px;
  margin-bottom: 0;
  cursor: pointer;
}

.fa-star {
  color: #f1c503;
}

textarea {
  margin-top: 10px;
  resize: none;
}

.btn-primary {
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 100%, 100%;
}

.carousel-control-prev {
  left: -5%;
}

.carousel-control-next {
  right: -4%;
}

.carousel-inner {
  transition: transform 0.5s ease-in-out;
}

.card.book-card {
  position: relative;
  overflow: hidden;
}

.card.book-card img {
  transition: transform 0.3s ease;
}

.card.book-card:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(4px);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 12px;
}

.card.book-card:hover .overlay {
  top: 0;
  opacity: 1;
}

.overlay-content {
  text-align: center;
}

.overlay-title,
.overlay-author {
  margin: 0;
}
</style>
