<template>
  <div v-if="show" class="modal" tabindex="-1" role="dialog" @click.self="close">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" @click="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="title-container">
            <p class="modal-title">{{ content.title }}</p>
            <div v-if="content.actualWord" class="actual-word-container">
              <span class="actual-word">{{ content.actualWord }}</span>
              <button class="audio-button btn-sm" @click="playAudio(content.actualWord)">
                <i class="bi bi-volume-up-fill"></i>
              </button>
            </div>
          </div>
          <div v-if="content.relatedWords">
            <strong>Related Words</strong> = {{ content.relatedWords }}
            <br><br>
          </div>
          <div v-if="content.description" v-html="content.description"></div>
          <p class="read-more"><a href="#" @click.prevent="redirectToInfo">Read More...</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contentData } from "@/data/contentData.js";

export default {
  props: {
    show: Boolean,
    word: String
  },
  computed: {
    content() {
      return contentData[this.word] || contentData.default;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    redirectToInfo() {
      this.$router.push({ name: "AddInfoComponent" });
      this.close();
    },
    playAudio(text) {
      if (window.speechSynthesis) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'he-IL'; // Hebrew language
        window.speechSynthesis.speak(utterance);
      } else {
        alert("Sorry, your browser does not support text-to-speech!");
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: relative;
  margin: auto;
  max-width: 500px;
}

.modal-content {
  background-color: #f4f0db;
  border-radius: 0.3rem;
  outline: 0;
  position: relative;
  padding: 1.5rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

p.read-more {
  color: #7a4b09;
  text-decoration: underline;
}

.read-more {
  text-align: right;
  margin-top: 1rem;
}

.read-more a {
  color: #7a4b09;
  text-decoration: none;
}

.read-more a:hover {
  text-decoration: underline;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none;
  background: transparent;
  padding: 0;
}

.close:hover {
  color: #7a4b09;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

.btn-2 {
  background-color: #f1c053;
  color: #000000;
  border-color: #f1c053;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.modal-title {
  margin: 0;
  font-weight: bold;
}

.actual-word-container {
  display: flex;
  align-items: center;
}

.actual-word {
  direction: rtl;
  margin-right: 5px;
}

.audio-button {
  width: 30px;
  height: 30px;
  background-color: #f1c053;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.audio-button:hover {
  background-color: #e0b048;
}

.audio-button i {
  font-size: 16px;
  color: #7a4b09;
}
</style>
