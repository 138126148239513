import axios from 'axios';

const api = axios.create({
  //FOR PRODUCTION
  baseURL: 'https://api.wisdomebooksclub.com/api'

  //FOR DEVELOPMENT
  //baseURL: 'http://localhost:8000/api'
});

// Set the AUTH token for any request
api.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;
