<template>
  <NavbarComponent />

  <body>
    <div class="ebooks-page">
      <!-- Featured Book Section -->
      <div v-if="isLoading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
        <Spinner />
      </div>
      <div v-else>
        <section class="featured-book text-center text-white py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-6 d-flex justify-content-center">
                <img :src="featuredBook.book_cover ? storage + featuredBook.book_cover : defaultBook" :alt="featuredBook.book_title" class="img-fluid rounded" />
              </div>
              <div class="col-md-6 d-flex flex-column justify-content-center">
                <h1 class="display-4 text-white">{{ featuredBook.book_title }}</h1>
                <p class="lead">{{ featuredBook.book_description }}</p>
                <div class="buttons d-flex mt-2">
                  <!-- <button class="btn btn-primary m-2" @click="navigateToBook(featuredBook.id)">
                  <i class="bi bi-play-fill" style="color: black; padding: 0%;"></i> Read Now
                </button> -->
                  <button class="btn btn-primary m-2" @click="checkSubscription(featuredBook)">
                    <i class="bi bi-play-fill" style="color: black; padding: 0%;"></i> Read Now
                  </button>
                  <button class="btn btn-secondary m-2" @click="openDetailModal(featuredBook)">
                    <i class="bi bi-info-circle" style="padding: 0%;"></i> More Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- <div class="categories-section" id="categories">
          <BooksCarousel v-for="book in filteredBooks" :key="book.id" :books="book.books" :title="book.categoryName"
            @open-modal="openModal" />
        </div>

        <div class="categories-section" id="genres">
          <h2 class="text-center">Featured Genres</h2>
          <BooksCarousel v-for="genre in featuredGenres" :key="genre.id" :books="genre.books"
            :title="genre.category_name" @open-modal="openModal" />
        </div> -->

        <div class="categories-section" id="categories">
          <BooksCarousel v-for="book in filteredBooks" :key="book.id" :books="book.books" :title="book.categoryName"
            :modalId="'categoryModal-' + book.categoryName" @close-books-modal="closeBooksModal" @open-detail-modal="openDetailModal" @open-view-modal="openViewAllModal" @close-all-modals="closeAllModals"/>
        </div>

        <div class="categories-section" id="genres">
          <h2 class="text-center">Featured Genres</h2>
          <BooksCarousel v-for="genre in featuredGenres" :key="genre.id" :books="genre.books"
            :title="genre.category_name" :modalId="'genreModal-' + genre.id" @close-books-modal="closeBooksModal" @open-detail-modal="openDetailModal" @open-view-modal="openViewAllModal" @close-all-modals="closeAllModals"/>
        </div>
      </div>
      <div>

      </div>
      <!-- Book Details Modal -->
      <BookModal :book="selectedBook" :subscription="userSubscription" ref="bookModal" @close-all-modals="closeAllModals"/>
    </div>
  </body>
  <FooterComponent />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import NavbarComponent from '../components/NavbarComponent.vue';
import BookModal from '../components/eBooks/BookModal.vue';
import BooksCarousel from '../components/eBooks/BooksCarousel.vue';
import FooterComponent from '../components/FooterComponent.vue';
import api from '@/api';
import storage from '@/constants'
import Spinner from '@/components/LoadingSpinner.vue'
import defaultBook from '@/assets/images/coverless-book.jpg';

const selectedBook = ref({});
const books = ref([]);
const featuredBook = ref([]);
const featuredGenres = ref([]);
const bookModal = ref(null);
const user = JSON.parse(sessionStorage.getItem('user'));
const userSubscription = user ? user.subscription : null;
const isLoading = ref(false);

const fetchSpecialCategories = async () => {
  isLoading.value = true;
  try {
    const response = await api.get('/categories-special');
    const categories = response.data;

    const formattedBooks = categories
      .filter(category => !category.name.toLowerCase().includes('feature')) 
      .map(category => {
        return {
          categoryName: category.name,
          books: category.books
        };
      });

    books.value = formattedBooks;

    const featuredCategory = categories.find(category => category.name.toLowerCase().includes('feature'));
    if (featuredCategory && featuredCategory.books.length > 0) {
      featuredBook.value = featuredCategory.books[0];
    }

    const featuredGenresCategory = categories.find(category => category.name.toLowerCase() === 'featured genres');
    if (featuredGenresCategory) {
      featuredGenres.value = featuredGenresCategory.genres;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};


const filteredBooks = computed(() => {
  return books.value.filter(book => book.categoryName !== 'Featured');
});


const router = useRouter();

const openDetailModal = (book) => {
  selectedBook.value = book;
  if (bookModal.value) {
    bookModal.value.showModal();
  }
};

const openViewAllModal = (modalId) => {
  const modal = new bootstrap.Modal(document.getElementById(modalId));
  modal.show();
}

const closeAllModals = () => {
  const modals = document.querySelectorAll('.modal');
  modals.forEach(modal => {
    const modalInstance = bootstrap.Modal.getInstance(modal);
    modalInstance.hide();
  });
}

const closeBooksModal = (modalId) => {
  console.log(modalId)
  const modal = new bootstrap.Modal(document.getElementById(modalId));
  modal.hide()
}

const checkSubscription = (book) => {
  if (userSubscription === 'Gold' || userSubscription === 'Platinum') {
    navigateToBook(book);
  } else {
    alert('You need to subscribe to read this book');
    router.push({ name: 'Subscription' });
  }
};

const navigateToBook = (book) => {
  const bookDetails = {
    title: book.book_title,
    author: book.author,
    description: book.book_description,
    book_id: book.id
  };

  localStorage.setItem('bookDetails', JSON.stringify(bookDetails));

  const formattedTitle = book.book_title.toLowerCase().replace(/\s+/g, '-');
  router.push({
    name: 'BookPdf',
    params: { id: book.id, title: formattedTitle },
  });
};

onMounted(() => {
  fetchSpecialCategories();
});
</script>

<style scoped>
body {
  background-color: #110f0d;
}

.ebooks-page {
  margin-top: 131px;
  font-family: "Glegoo", sans-serif;
}

.featured-book {
  background-color: #100f0d;
}

.books-grid {
  background-color: #110f0d;
}

.books-grid .section-title {
  padding-bottom: 60px;
  position: relative;
  color: #f1c503;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  display: inline-block;
}

.categories-section h2 {
  color: #ffffff;
  font-size: 36px;
  margin: 150px 0 0 0;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}

.card {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 270px;
  border: none;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.card-img-top {
  height: 300px;
  border-bottom: 2px solid #f1c503;
}

.card-img-top img {
  width: 80%;
  height: 100%;
  object-fit: cover;
}

.buttons {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  font-family: "Nunito", sans-serif;
}

.btn-primary {
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}

.lead {
  font-size: 14px;
}

.popular-books {
  background-color: #110f0d;
  padding: 40px 0;
}

.popular-books h2 {
  color: #f1c503;
  font-size: 24px;
  margin-bottom: 30px;
}

/* Browse All Button Styling */
.btn-browse-all {
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 350px;
}

.btn-browse-all:hover {
  background-color: #110f0d;
  color: #f1c503;
}
</style>
