<template>
  <header id="header" class="header d-flex align-items-center fixed-top" v-if="showHeader">
    <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
      <a href="/home" class="logo d-flex align-items-center me-auto me-lg-0">
        <img src="../assets/images/wisdom-dark.png" alt="" />
        <h1 class="sitename">Wisdom e<span>B</span>ooks Club</h1>
      </a>
      <nav id="navmenu" class="navmenu">
        <ul>
          <li><router-link to="/home">Home</router-link></li>
          <li class="dropdown">
            <a href="#"><span>eBooks</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li><router-link to="/book-library">Book Library</router-link></li>
              <!-- <li><router-link to="/ebooks#categories">Book Categories</router-link></li> -->
            </ul>
          </li>
          <li class="dropdown">
            <a href="https://wisdomebooksclub.com/pearls-of-wisdom/"><span>Pearls of Wisdom</span>
              <i class="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li><a href="https://wisdomebooksclub.com/pearls-of-wisdom/">Pearls of Wisdom</a></li>
              <li>
                <a href="https://wisdomebooksclub.com/pearls-of-wisdom/ancient-hebrew-calendar/"><span>Ancient Hebrew Calendar</span></a>
              </li>
              <li><a href="https://wisdomebooksclub.com/pearls-of-wisdom/wisdom-blogs/">Wisdom Blogs</a></li>
            </ul>
          </li>
          <!-- <li class="dropdown">
            <a href="#"><span>eStudy Bible</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li>
                <router-link to="/parallel">Parallel: Side-by-Side Bible</router-link>
              </li>
              <li>
                <router-link to="/kingjames">King James Hebrew-Greek Interlinear Bible</router-link>
              </li>
              <li>
                <router-link to="crossreference">Cross-Reference Bible</router-link>
              </li>
              <li><router-link to="wisdombible">Wisdom Bible Plus</router-link></li>
              <li><router-link to="bibleatlas">Bible Atlas</router-link></li>
            </ul>
          </li> -->
          <li v-if="!isLoggedIn"><router-link to="/login">Login</router-link></li>

          <!-- If logged in, show user's first name and dropdown menu -->
          <li v-else class="dropdown">
            <a href="#"><span>{{ user.first_name }}</span><i class="bi bi-chevron-down toggle-dropdown"></i></a>
            <div class="dropdown-content">
              <router-link to="/profile">My Account</router-link>
              <router-link to="">My Library</router-link>
              <a href="#" @click="logout">Log out</a>
            </div>
          </li>

        </ul>
        <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>
      <a class="btn-getstarted" href="/subscription">Subscribe</a>
      <div class="search-container ms-3">
        <i class="bi bi-search search-icon"></i>
        <input type="text" class="search-input form-control" placeholder="Search..." />
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, provide, watch } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';

const showHeader = ref(true);
const user = ref(null);
const isLoggedIn = ref(false);
const router = useRouter();

const props = defineProps({
  forceFetch: {
    type: Boolean,
    default: false,
  },
});

const isLoggedInComputed = computed(() => {
  const token = sessionStorage.getItem('token');
  return !!token;
});

const fetchUser = async (forceFetch = false) => {
  if (!isLoggedInComputed.value) return;

  if (!forceFetch) {
    const cachedUser = sessionStorage.getItem('user');
    if (cachedUser) {
      user.value = JSON.parse(cachedUser)
      isLoggedIn.value = true;
      provide('user', user);
      return;
    }
  }

  try {
    const response = await api.get('/user');
    const userData = response.data;

    // Store only non-sensitive data in sessionStorage
    const nonSensitiveUserData = {
      id: userData.id,
      first_name: userData.first_name,
      email: userData.email,
      subscription: userData.subscription,
      avatar: userData.avatar,
    };

    user.value = nonSensitiveUserData;
    isLoggedIn.value = true;

    sessionStorage.setItem('user', JSON.stringify(nonSensitiveUserData));
    //sessionStorage.setItem('user', nonSensitiveUserData);

    provide('user', user);
  } catch (error) {
    console.error("Failed to fetch user details:", error);
  }
};

watch(() => props.forceFetch, (newVal) => {
  if (newVal) {
    fetchUser(true);
  }
});

// Initial fetch if needed
if (props.forceFetch) {
  fetchUser(true);
}

const logout = async () => {
  await api.post('/logout', {}, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  user.value = null;
  isLoggedIn.value = false;

  router.push('/login');
};

onMounted(() => {
  fetchUser();

  const intervalId = setInterval(() => {
    fetchUser(true);
  }, 3600000);

  onUnmounted(() => {
    clearInterval(intervalId);
  });
});

provide('user', user);
provide('isLoggedIn', isLoggedIn);
</script>

<style scoped>
.header {
  margin-bottom: 80px;
}
.container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 0;
  padding: 5px 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  visibility: hidden;
  opacity: 0;
  transition: width 0.4s ease-in-out, visibility 0.4s, opacity 0.4s;
}

.search-icon {
  font-size: 20px;
  cursor: pointer;
  color: #f1c503;
  padding-left: 5px;
}

.search-container:hover .search-input {
  width: 200px;
  visibility: visible;
  opacity: 1;
  margin-left: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
};
</style>
