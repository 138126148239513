<template>
  <div class="register-container">
    <div class="inner-container">
      <div class="info-container">
        <h2 style="margin-top: 220px;">New Here?</h2>
        <p>Create an account to join our community and enjoy our eBooks collection.</p>
        <div class="button-container">
          <ReusableButton buttonClass="btn-secondary" @click="navigateToLogin">Login</ReusableButton>
        </div>
      </div>
      <div class="form-container">
        <h1 class="register-text">Register</h1>
        <form @submit.prevent="handleSubmit">
          <h1 class="welcome-text">Be a part of Wisdom eBooks Club!</h1>
          <ReusableFormInput label="First Name" type="text" id="first" placeholder="Enter your First Name"
            v-model:value="firstName" />
          <div v-if="errors.first_name" class="error-messages">
            <p v-for="error in errors.first_name" :key="error">{{ error }}</p>
          </div>
          <ReusableFormInput label="Last Name" type="text" id="last" placeholder="Enter your Last Name"
            v-model:value="lastName" />
          <div v-if="errors.last_name" class="error-messages">
            <p v-for="error in errors.last_name" :key="error">{{ error }}</p>
          </div>
          <ReusableFormInput label="Email Address" type="email" id="email" placeholder="example@gmail.com"
            v-model:value="email" />
          <div v-if="errors.email" class="error-messages">
            <p v-for="error in errors.email" :key="error">{{ error }}</p>
          </div>
          <ReusableFormInput label="Password" type="password" id="password" placeholder="Enter Password"
            v-model:value="password" />
          <div v-if="errors.password" class="error-messages">
            <p v-for="error in errors.password" :key="error">{{ error }}</p>
          </div>
          <ReusableFormInput label="Confirm Password" type="password" id="confirmPassword"
            placeholder="Confirm Password" v-model:value="confirmPassword" />
          <div class="terms">
            <p>By continuing, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</p>
          </div>
          <div class="alternatives">
            <p>Or register with:</p>
            <div class="alternatives-2">
              <button type="button" class="btn-google" @click="registerWithGoogle">
                <font-awesome-icon :icon="['fab', 'google']" />
              </button>
              <button type="button" class="btn-facebook" @click="registerWithFacebook">
                <font-awesome-icon :icon="['fab', 'facebook']" />
              </button>
            </div>
          </div>
          <div class="button-container">
            <ReusableButton buttonClass="btn-primary" @click="handleSubmit">Register</ReusableButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFormInput from '../components/forms/ReusableFormInput.vue';
import ReusableButton from '../components/forms/ReusableButton.vue';
import api from '@/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: "RegisterPage",
  components: {
    ReusableFormInput,
    ReusableButton
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      errors: {},
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const formData = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword
        };

        const response = await api.post('/register', formData)
        if (response.status === 201) {
          this.notifySuccess('Registration successful. Redirecting to login page...');
          setTimeout(() => {
            this.$router.push('/login');
          }, 3000);
        }
      } catch (err) {
        this.notifyError('Registration failed. Please check the form for errors');
        if (err.response && err.response.status === 422) {
          this.errors = err.response.data.errors;
        } else {
          this.notifyError('An error has occured. Please try again.');
          console.error(err);
        }
      }
    },
    notifySuccess(message = '') {
      const toastId = toast.success(message, {
        theme: 'colored',
        autoClose: 2000,
      })
      return toastId
    },
    notifyError(message = '') {
      const toastId = toast.error(message, {
        theme: 'colored',
        autoClose: 2000,
      })
      return toastId
    },
    navigateToLogin() {
      this.$router.push('/login');
    },
    registerWithGoogle() {
      // Handle Google registration
    },
    registerWithFacebook() {
      // Handle Facebook registration
    }
  }
};
</script>

<style scoped>
.register-container {
  background: #110f0d;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/wec-sign-in-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
}

.inner-container {
  display: flex;
  max-width: 900px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.info-container,
.form-container {
  width: 50%;
  padding: 20px;
}

.info-container {
  background-image: url("../assets/images/Visitors-1-min.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 1;
}

.form-container {
  background-color: #110f0d;
  border-left: 1px solid #fff;
  position: relative;
  z-index: 2;
}

.info-container h2 {
  color: #fff;
  font-family: "Glegoo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
}

.info-container p {
  color: #fff;
  font-size: 16px;
}

.register-text {
  color: #f1c503;
  font-family: "Glegoo", sans-serif;
  font-weight: 700;
  font-size: 19px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 7px;
}

.welcome-text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.mb-3 {
  font-size: 12px;
}

.btn-google,
.btn-facebook {
  background-color: #fff;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  max-width: 140px;
}

.btn-google .fa-google,
.btn-facebook .fa-facebook {
  color: #f1c503;
}

.btn-google:hover,
.btn-facebook:hover {
  opacity: 0.9;
}

.terms {
  color: #fff;
  font-size: 12px;
}

.terms a {
  color: #f1c503;
}

.alternatives {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alternatives p {
  color: #f1c503;
  text-align: center;
  margin: 0;
}

.alternatives div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.alternatives-2 {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.error-messages {
  background-color: #2c2c2c;
  color: #ff6b6b;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.error-messages p {
  margin: 0;
  font-size: 14px;
}
</style>
