<template>
    <NavbarComponent />
    <div class="container custom-container">
        <div class="header-subscription">
            <h1>Go Premium Now!</h1>
            <p>Go Premium for Additional Features and remove Distractions!</p>
            <p>You can pay through Stripe, debit or credit card. Just click the subscribe button below for your
                preferred subscription.</p>
        </div>
        <!-- <div class="subscription-container">
            <div class="subscription-option gold">
                <h2>Gold</h2>
                <p>Billed Annually</p>
                <hr>
                <h2 class="price"><strong>$23.88</strong></h2>
                <button @click="createCheckoutSession('price_1Pt0f2DGYmTnKDc3JHuEIoLe')" class="button">Subscribe</button>
                <hr>
                <p><strong>$1.99/month</strong></p>
                <p>Pearls of Wisdom</p>
                <p>Access to ALL Religious eBooks</p>
                <p>Wisdom eStudy Bible</p>
            </div>

            <div class="subscription-option platinum">
                <h2>Platinum</h2>
                <p>Billed Annually</p>
                <hr>
                <h2 class="price"><strong>$35.88</strong></h2>
                <button @click="createCheckoutSession('price_1Pt0ebDGYmTnKDc3jswx5pSW')" class="button">Subscribe</button>
                <hr>
                <p><strong>$2.99/month</strong></p>
                <p>Pearls of Wisdom</p>
                <p>Access to ALL Religious eBooks</p>
                <p>Wisdom eStudy Bible</p>
            </div>
        </div> -->
        <div id="stripe-pricing-table"></div>
      
    </div>

    <FooterComponent />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import api from '@/api';
import { loadStripe } from '@stripe/stripe-js';

const stripe = ref(null);
const cardElement = ref(null);
const email = ref('');
const cachedUser = sessionStorage.getItem('user');
let userId = null;

if (cachedUser) {
  userId = JSON.parse(cachedUser).id;
}

onMounted(async () => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    script.onload = () => {
        const stripeTable = document.createElement('stripe-pricing-table');
        stripeTable.setAttribute('pricing-table-id', 'prctbl_1Pt8nFDGYmTnKDc3Mht9KpPu');
        stripeTable.setAttribute('publishable-key', 'pk_test_51NFxwiDGYmTnKDc3uadReMQuYKjQEED5MmU8z6GOgOSa2oPeJpsY7bn2QqeiezvmgV275WCT3oepMaPcqF7vRgyD00IzHgsGvJ');
        stripeTable.setAttribute('client-reference-id', userId);

        document.getElementById('stripe-pricing-table').appendChild(stripeTable);
    };

    document.head.appendChild(script);
});

const handleSubmit = async () => {
    const { paymentMethod, error } = await stripe.value.createPaymentMethod('card', cardElement.value);

    if (error) {
        console.error(error);
    } else {
        try {
            const response = await api.post('/recurring', {
                email: email.value,
                payment_method_id: paymentMethod.id,
                membership_type: 'monthly_gold'
            });

            if (response.data.error) {
                console.error(response.data.error);
            } else {
                console.log('Payment successful:', response.data.paymentIntent);
            }
        } catch (err) {
            console.error(err);
        }
    }
};

const createCheckoutSession = async (priceId) => {
  try {
    const response = await api.post('/stripe/create-checkout-session', {
      price_id: priceId,
      user_id: userId
    });

    const sessionId = response.data.id;
    const stripeInstance = await stripe.value;
    await stripeInstance.redirectToCheckout({ sessionId });
  } catch (err) {
    console.error(err);
  }
};
</script>

<style scoped>
.custom-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 100%;
    background-color: #f4f0db;
    position: relative;
    margin-top: 100px;
}

.header-subscription {
    text-align: center;
}

.subscription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.subscription-option {
    width: 300px;
    margin: 0 20px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.platinum {
    background-color: #ddb236;
    color: #0c1212;
}

.gold {
    background-color: #696763;
    color: #ddb236;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0c1212;
    color: #ddb236;
    text-decoration: none;
    border-radius: 5px;
    transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.button:hover {
    transform: scale(1.1); /* Enlarge the button slightly */
}

.price {
    font-size: 50px;
    font-weight: bold;
    color: red
}

.footer {
    text-align: center;
    padding: 20px;
    background-color: #0c1212;
    color: #ddb236;
}

/* for the card information */
.payment-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}
</style>