<template>
  <div>
    <NavbarComponent />
    <div class="container-fluid main-container">
      <h1 class="title text-center">Wisdom Bible Plus</h1>
      <!-- Navbar -->
      <div class="nav-bar d-flex justify-content-center align-items-center mt-3 position-relative">
        <div class="d-flex justify-content-center gap-3">
          <!-- The Book and Chapter select components have been moved to the modal -->
        </div>
      </div>
      <!-- End of Navbar -->
      <div class="settings-icon">
        <FilterIconComponent @click="handleFirstIconClick" />
        <div class="audio-controls">
          <button class="audio-button btn-sm" @click="toggleAmbientSound">
            <i :class="ambientSoundPlaying ? 'bi bi-volume-mute-fill' : 'bi bi-volume-down-fill'"></i>
          </button>
          <button class="audio-button btn-sm" @click="toggleAudio">
            <i :class="isPlaying ? 'bi bi-pause-fill' : 'bi bi-play-fill'"></i>
          </button>
          <button class="audio-button btn-sm" @click="stopAudio">
            <i class="bi bi-stop-fill"></i>
          </button>
        </div>
        <!-- Toggle Button for New Viewer -->
        <button class="toggle-viewer-button btn-sm" @click="toggleSecondViewer">
          <img :src="currentToggleIcon" alt="Toggle Viewer Icon" />
        </button>
      </div>

      <div class="prev">
        <PreviousButton @previous="goToPreviousChapter" />
      </div>
      <div class="next">
        <NextButton @next="goToNextChapter" />
      </div>

      <!-- Book and Chapter -->
      <div class="row mt-3">
        <!-- First Viewer Column -->
        <div class="col-md-12" v-if="!showSecondViewer">
          <h4 class="book-name text-center" @click="handleFirstIconClick">{{ selectedBook }}</h4>
          <h2 class="book-chapter text-center" @click="handleFirstIconClick">Chapter {{ selectedChapter }}</h2>
          <VerseComponent ref="verseContent" />
        </div>
        <!-- Two Viewers Columns -->
        <div class="col-md-6" v-if="showSecondViewer">
          <!-- First Viewer -->
          <h4 class="book-name text-center">{{ selectedBook }}</h4>
          <h2 class="book-chapter text-center">Chapter {{ selectedChapter }}</h2>
          <VerseComponent ref="verseContent" />
        </div>
        <div class="col-md-6" v-if="showSecondViewer">
          <!-- Second Viewer -->
          <h2 class="commentaries text-center">Commentaries</h2>
          <VerseComponent ref="secondVerseContent" />
        </div>
      </div>
      <!-- End of Book and Chapter -->

      <!-- Filter Modal -->
      <div v-if="showFilterModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <div class="row">
            <div class="col-md-6">
              <h2>Filter Options</h2>
              <div class="form-group">
                <label for="bookDropdown">Book</label>
                <div class="modal-dropdown">
                  <button class="btn dropdown-toggle" type="button" id="bookDropdownButton" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ selectedBook }}
                  </button>
                  <ul class="dropdown-menu p-3" aria-labelledby="bookDropdownButton">
                    <li>
                      <input class="form-control me-2 dropdown-search" type="search" v-model="bookSearch"
                        placeholder="Search books" aria-label="Search">
                    </li>
                    <li v-for="book in filteredBooks" :key="book" @click="selectBook(book)">
                      <a class="dropdown-item" href="#">{{ book }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h2>&nbsp;</h2> <!-- Empty header for spacing on smaller screens -->
              <div class="form-group">
                <label for="chapterDropdown">Chapter</label>
                <div class="modal-dropdown">
                  <button class="btn dropdown-toggle" type="button" id="chapterDropdownButton" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ selectedChapter }}
                  </button>
                  <ul class="dropdown-menu p-3" aria-labelledby="chapterDropdownButton">
                    <li>
                      <input class="form-control me-2 dropdown-search" type="search" v-model="chapterSearch"
                        placeholder="Search chapters" aria-label="Search">
                    </li>
                    <li v-for="chapter in filteredChapters" :key="chapter" @click="selectChapter(chapter)">
                      <a class="dropdown-item" href="#">{{ chapter }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="modal-buttons">
              <button @click="applyFilters" class="btn btn-1">Apply</button>
              <button @click="closeFilterModal" class="btn btn-2 btn-secondary">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter Modal -->

    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import PreviousButton from "@/components/eStudy/PreviousButton.vue";
import NextButton from "@/components/eStudy/NextButton.vue";
import VerseComponent from "@/components/BiblePlus/VerseComponent.vue";
import FilterIconComponent from "@/components/FilterIconComponent.vue";

export default {
  components: {
    NavbarComponent,
    FooterComponent,
    PreviousButton,
    NextButton,
    VerseComponent,
    FilterIconComponent,
  },
  data() {
    return {
      books: ["Genesis",
        "Exodus",
        "Leviticus",
        "Numbers",
        "Deuteronomy",
        "Joshua",
        "Judges",
        "Ruth",
        "1 Samuel",
        "2 Samuel",
        "1 Kings",
        "2 Kings",
        "1 Chronicles",
        "2 Chronicles",
        "Ezra",
        "Nehemiah",
        "Esther",
        "Job",
        "Psalms",
        "Proverbs",
        "Ecclesiastes",
        "Song of Solomon",
        "Isaiah",
        "Jeremiah",
        "Lamentations",
        "Ezekiel",
        "Daniel",
        "Hosea",
        "Joel",
        "Amos",
        "Obadiah",
        "Jonah",
        "Micah",
        "Nahum",
        "Habakkuk",
        "Zephaniah",
        "Haggai",
        "Zechariah",
        "Malachi",
        "Matthew",
        "Mark",
        "Luke",
        "John",
        "Acts",
        "Romans",
        "1 Corinthians",
        "2 Corinthians",
        "Galatians",
        "Ephesians",
        "Philippians",
        "Colossians",
        "1 Thessalonians",
        "2 Thessalonians",
        "1 Timothy",
        "2 Timothy",
        "Titus",
        "Philemon",
        "Hebrews",
        "James",
        "1 Peter",
        "2 Peter",
        "1 John",
        "2 John",
        "3 John",
        "Jude",
        "Revelation"],
      chapters: Array.from({ length: 50 }, (_, i) => i + 1),
      selectedBook: "Genesis",
      selectedChapter: 1,
      speechSynthesis: window.speechSynthesis,
      utterance: null,
      isPlaying: false,
      showSecondViewer: false,
      showFilterModal: false,
      ambientSoundPlaying: false,
      ambientAudio: null,
      toggleIcon1: require('../assets/images/add-layer.png'), // replace with your actual image path
      toggleIcon2: require('../assets/images/minus-layer.png'), // replace with your actual image path
      currentToggleIcon: require('../assets/images/add-layer.png'), // set the initial image
      bookSearch: '',
      chapterSearch: '',
      secondBookSearch: '',
      secondChapterSearch: '',
    };
  },
  computed: {
    filteredBooks() {
      return this.books.filter(book => book.toLowerCase().includes(this.bookSearch.toLowerCase()));
    },
    filteredChapters() {
      return this.chapters.filter(chapter => chapter.toString().includes(this.chapterSearch));
    }
  },
  methods: {
    handleFirstIconClick() {
      this.showFilterModal = true;
    },
    closeModal() {
      this.showFilterModal = false;
    },
    applyFilters() {
      this.showFilterModal = false;
    },
    selectBook(book) {
      this.selectedBook = book;
      this.bookSearch = ""; // Clear search input after selection
    },
    selectChapter(chapter) {
      this.selectedChapter = chapter;
      this.chapterSearch = ""; // Clear search input after selection
    },
    closeFilterModal() {
      this.showFilterModal = false;
    },
    toggleSecondViewer() {
      this.showSecondViewer = !this.showSecondViewer;
      this.currentToggleIcon = this.currentToggleIcon === this.toggleIcon1 ? this.toggleIcon2 : this.toggleIcon1;
    },
    toggleAudio() {
      if (this.isPlaying) {
        this.pauseAudio();
      } else {
        this.playAudio();
      }
    },
    playAudio() {
      const verseContentComponent = this.$refs.verseContent;
      if (verseContentComponent && verseContentComponent.$el) {
        const text = this.getVerseTextWithNumbers(verseContentComponent.$el.textContent);
        if (text) {
          if (!this.utterance) {
            this.utterance = new SpeechSynthesisUtterance(text);
            this.utterance.onend = () => {
              this.isPlaying = false;
              this.utterance = null; // Reset the utterance after speech ends
            };
            this.speechSynthesis.speak(this.utterance);
          } else if (this.speechSynthesis.paused) {
            this.speechSynthesis.resume();
          }
          this.isPlaying = true;
        }
      }
    },
    pauseAudio() {
      if (this.speechSynthesis.speaking && !this.speechSynthesis.paused) {
        this.speechSynthesis.pause();
        this.isPlaying = false;
      }
    },
    stopAudio() {
      if (this.speechSynthesis.speaking) {
        this.speechSynthesis.cancel();
        this.utterance = null;
        this.isPlaying = false;
      }
    },
    getVerseTextWithNumbers(text) {
      return text.replace(/(\d+)(?=\s)/g, "Verse $1");
    },
    toggleAmbientSound() {
      if (this.ambientSoundPlaying) {
        this.ambientAudio.pause();
        this.ambientAudio.currentTime = 0;
      } else {
        if (!this.ambientAudio) {
          this.ambientAudio = new Audio(require('../sounds/ambient-sound.mp3'));
          this.ambientAudio.loop = true;
        }
        this.ambientAudio.play();
      }
      this.ambientSoundPlaying = !this.ambientSoundPlaying;
    },
    goToPreviousChapter() {
      if (this.selectedChapter > 1) {
        this.selectedChapter -= 1;
      } else if (this.selectedBook > 0) {
        this.selectedBook -= 1;
        this.selectedChapter = this.chapters.length;
      }
    },
    goToNextChapter() {
      if (this.selectedChapter < this.chapters.length) {
        this.selectedChapter += 1;
      } else if (this.selectedBook < this.books.length - 1) {
        this.selectedBook += 1;
        this.selectedChapter = 1;
      }
    },
  },
  beforeUnmount() {
    if (this.ambientAudio) {
      this.ambientAudio.pause();
      this.ambientAudio.currentTime = 0;
    }
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.ambientAudio) {
      this.ambientAudio.pause();
      this.ambientAudio.currentTime = 0;
    }
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel();
    }
    next();
  }
};
</script>

<style scoped>
.container-fluid {
  height: 100%;
  width: 100%;
  background-color: #f4f0db;
  margin-top: 100px;
  padding: 0 180px 20px;
}

.title {
  color: #7a4b09;
  padding-top: 20px;
  font-size: 50px;
  font-weight: bold;
}

.book-name {
  color: #7a4b09;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer
}

.book-chapter {
  color: #7a4b09;
  font-size: 50px;
  font-weight: bold;
  cursor: pointer
}

.commentaries {
  color: #7a4b09;
  font-size: 50px;
  font-weight: bold;
  padding-top: 34px;
}

.audio-controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.audio-button {
  width: 40px;
  height: 40px;
  background-color: #f1c053;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.audio-button i {
  font-size: 16px;
}

.settings-icon {
  position: absolute;
  top: 200px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings-icon button {
  border: none;
  border-radius: 50%;
}

.toggle-viewer-button {
  background-color: #f1c053;
  color: #7a4b09;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.toggle-viewer-button img {
  width: 70%;
  height: 70%;
}

.prev,
.next {
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
}

.prev {
  left: 300px;
}

.next {
  right: 300px;
}

@media (max-width: 1200px) {
  .prev {
    left: 200px;
  }

  .next {
    right: 200px;
  }
}

@media (max-width: 992px) {
  .prev {
    left: 100px;
  }

  .next {
    right: 100px;
  }
}

@media (max-width: 768px) {
  .prev {
    left: 50px;
  }

  .next {
    right: 50px;
  }
}

@media (max-width: 576px) {

  .prev,
  .next {
    top: auto;
    bottom: 20px;
    left: 10px;
    right: 10px;
    justify-content: center;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}

/* Filter Modal CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f4f0db;
  /* Use the background color from .custom-container */
  padding: 30px;
  border-radius: 10px;
  max-width: 700px;
  /* Increased width for a more spacious look */
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #7a4b09;
  /* Use the color from .parallel-header h1 */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.modal-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Make dropdown container wider */
  color: #f1c053;
  background-color: #fff;
}

.modal-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* Make button take full width */
  justify-content: space-between;
  /* Space between text and caret */
  color: #7a4b09;
}

.modal-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
  width: 105%;
  /* Make dropdown menu take full width */
}

.modal-dropdown .dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-dropdown .dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.modal-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.modal-dropdown:hover .dropdown-menu {
  display: block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}

.btn {
  padding: 8px 16px;
  /* Reduced padding */
  font-size: 14px;
  /* Smaller font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-1 {
  background-color: #f1c053;
  color: #000;
}

.btn-1:hover {
  background-color: #e0b048;
}

.btn-2 {
  background-color: #ccc;
  color: #333;
}

.btn-2:hover {
  background-color: #b3b3b3;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 8px;
  margin: 8px;
  width: calc(100% - 16px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}
</style>
