<template>
  <div>
    <NavbarComponent />
    <div class="container-fluid">
      <h1 class="title text-center">Cross-Reference Bible</h1>
      <h5 class="title-description text-center">Treasury of Scripture Knowledge</h5>
      <div class="settings-icon">
        <FilterIconComponent @click="handleFirstIconClick" />
      </div>
      <div class="nav-buttons d-flex justify-content-center">
        <div class="prev">
          <PreviousButton @previous="goToPreviousChapter" />
        </div>
        <div class="next">
          <NextButton @next="goToNextChapter" />
        </div>
      </div>
      <div class="container mt-5 custom-container">
        <div class="col-md-6 column-content order-md-1 order-2">
          <div class="selector-row d-flex justify-content-center mb-5">
            <h2 class="book-name" @click="handleFirstIconClick">{{ selectedBook }}</h2>
            <h2 class="book-chapter" @click="handleFirstIconClick">{{ selectedChapter }}</h2>
            <span class="colon">:</span>
            <h2 class="book-verse" @click="handleFirstIconClick">{{ selectedVerseNumber }}</h2>
          </div>
          <div class="bible-word">
            <h3>{{ selectedVerse }}</h3>
          </div>
          <BibleVerseList :verses="verses" @verseSelected="selectVerse" />
        </div>
        <div class="col-md-6 column-content order-md-2 order-1">
          <h2 class="book-version text-center" @click="handleFirstIconClick">{{ selectedVersion }}</h2>
          <VerseContent :verse="selectedVerse" :content="selectedVerseContent" />
        </div>
      </div>
    </div>

    <!-- Filter Modal -->
    <div v-if="showFilterModal" class="modal-overlay" @click.self="closeFilterModal">
      <div class="modal-content">
        <div class="row">
          <div class="col-md-6">
            <h2>Filter Options</h2>
            <div class="form-group">
              <label for="bookDropdown">Book</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="bookDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedBook }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="bookDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="bookSearchQuery"
                      placeholder="Search books" aria-label="Search">
                  </li>
                  <li v-for="book in filteredBooks" :key="book" @click="selectBook(book)">
                    <a class="dropdown-item" href="#">{{ book }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2>&nbsp;</h2>
            <div class="form-group">
              <label for="chapterDropdown">Chapter</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="chapterDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedChapter }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="chapterDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="chapterSearchQuery"
                      placeholder="Search chapters" aria-label="Search">
                  </li>
                  <li v-for="chapter in filteredChapters" :key="chapter" @click="selectChapter(chapter)">
                    <a class="dropdown-item" href="#">{{ chapter }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2>&nbsp;</h2>
            <div class="form-group">
              <label for="verseDropdown">Verse</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="verseDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedVerseNumber }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="verseDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="verseNumberSearchQuery"
                      placeholder="Search verses" aria-label="Search">
                  </li>
                  <li v-for="verseNumber in filteredVerseNumbers" :key="verseNumber"
                    @click="selectVerseNumber(verseNumber)">
                    <a class="dropdown-item" href="#">{{ verseNumber }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2>&nbsp;</h2>
            <div class="form-group">
              <label for="versionDropdown">Version</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="versionDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedVersion }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="versionDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="versionSearchQuery"
                      placeholder="Search Versions" aria-label="Search">
                  </li>
                  <li v-for="version in filteredVersions" :key="version" @click="selectVersion(version)">
                    <a class="dropdown-item" href="#">{{ version }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="modal-buttons">
            <button @click="applyFilters" class="btn btn-1">Apply</button>
            <button @click="closeFilterModal" class="btn btn-2 btn-secondary">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>



<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import BibleVerseList from "@/components/CrossReference/BibleVerseList.vue";
import VerseContent from "@/components/CrossReference/VerseContent.vue";
import FilterIconComponent from "@/components/FilterIconComponent.vue";
import PreviousButton from "@/components/eStudy/PreviousButton.vue";
import NextButton from "@/components/eStudy/NextButton.vue";

export default {
  components: {
    NavbarComponent,
    FooterComponent,
    BibleVerseList,
    VerseContent,
    FilterIconComponent,
    PreviousButton,
    NextButton,
  },
  data() {
    return {
      selectedBook: "Genesis",
      selectedChapter: 1,
      selectedVerseNumber: 1,
      selectedVerse: "",
      selectedVerseContent: "Click on a reference to display information!",
      selectedVersion: "KJV",
      books: [
        "Genesis", "Exodus", "Leviticus", "Numbers", "Deuteronomy", "Joshua", "Judges", "Ruth", "1 Samuel", "2 Samuel",
        "1 Kings", "2 Kings", "1 Chronicles", "2 Chronicles", "Ezra", "Nehemiah", "Esther", "Job", "Psalms", "Proverbs",
        "Ecclesiastes", "Song of Solomon", "Isaiah", "Jeremiah", "Lamentations", "Ezekiel", "Daniel", "Hosea", "Joel",
        "Amos", "Obadiah", "Jonah", "Micah", "Nahum", "Habakkuk", "Zephaniah", "Haggai", "Zechariah", "Malachi",
        "Matthew", "Mark", "Luke", "John", "Acts", "Romans", "1 Corinthians", "2 Corinthians", "Galatians", "Ephesians",
        "Philippians", "Colossians", "1 Thessalonians", "2 Thessalonians", "1 Timothy", "2 Timothy", "Titus", "Philemon",
        "Hebrews", "James", "1 Peter", "2 Peter", "1 John", "2 John", "3 John", "Jude", "Revelation",
      ],
      chapters: Array.from({ length: 50 }, (_, i) => i + 1),
      verseNumbers: Array.from({ length: 50 }, (_, i) => i + 1),
      verses: ["Prov 8:22-24", "Prov 16:4", "John 1:1", "Gen 1:1"],
      versions: ["KJV", "NIV", "ESV", "NKJV", "NLT"],
      showFilterModal: false,
      bookSearchQuery: "",
      chapterSearchQuery: "",
      verseNumberSearchQuery: "",
      versionSearchQuery: "",
      filterSelectedBook: "Genesis",
      filterSelectedChapter: 1,
      filterSelectedVerseNumber: 1,
      filterSelectedVersion: "NKJV",
    };
  },
  computed: {
    filteredBooks() {
      const query = this.bookSearchQuery.toLowerCase();
      return this.books.filter(book => book.toLowerCase().includes(query));
    },
    filteredChapters() {
      const query = this.chapterSearchQuery.toLowerCase();
      return this.chapters.filter(chapter => chapter.toString().includes(query));
    },
    filteredVerseNumbers() {
      const query = this.verseNumberSearchQuery.toLowerCase();
      return this.verseNumbers.filter(verseNumber => verseNumber.toString().includes(query));
    },
    filteredVersions() {
      const query = this.versionSearchQuery.toLowerCase();
      return this.versions.filter(version => version.toLowerCase().includes(query));
    },
  },
  methods: {
    handleFirstIconClick() {
      this.showFilterModal = true;
    },
    closeFilterModal() {
      this.showFilterModal = false;
    },
    selectBook(book) {
      this.filterSelectedBook = book;
      this.selectedBook = book;
      this.closeFilterModal();
    },
    selectChapter(chapter) {
      this.filterSelectedChapter = chapter;
      this.selectedChapter = chapter;
      this.closeFilterModal();
    },
    selectVerseNumber(verseNumber) {
      this.filterSelectedVerseNumber = verseNumber;
      this.selectedVerseNumber = verseNumber;
      this.closeFilterModal();
    },
    selectVersion(version) {
      this.filterSelectedVersion = version;
      this.selectedVersion = version;
      this.closeFilterModal();
    },
    applyFilters() {
      // Implement your logic to apply filters
      this.closeFilterModal();
    },
    goToPreviousChapter() {
      if (this.selectedChapter > 1) {
        this.selectedChapter--;
      } else {
        const bookIndex = this.books.indexOf(this.selectedBook);
        if (bookIndex > 0) {
          this.selectedBook = this.books[bookIndex - 1];
          this.selectedChapter = this.chapters[this.chapters.length - 1];
        }
      }
    },
    goToNextChapter() {
      if (this.selectedChapter < this.chapters.length) {
        this.selectedChapter++;
      } else {
        const bookIndex = this.books.indexOf(this.selectedBook);
        if (bookIndex < this.books.length - 1) {
          this.selectedBook = this.books[bookIndex + 1];
          this.selectedChapter = 1;
        }
      }
    },
    selectVerse(verse) {
      this.selectedVerse = verse;
      this.selectedVerseContent = "Content of the selected verse.";
    }
  },
};
</script>



<style scoped>
.container-fluid {
  height: 100vh;
  width: 100%;
  background-color: #f4f0db;
  margin-top: 100px;
  padding-left: 60px;
  /* Add padding to ensure buttons do not overlap */
  padding-right: 60px;
  /* Add padding to ensure buttons do not overlap */
}

.title {
  color: #7a4b09;
  padding-top: 20px;
  font-size: 50px;
  font-weight: bold;
}

.title-description {
  color: #000;
  font-size: 25px;
}

.book-name {
  font-size: 30px;
  color: #e0b048;
  font-weight: 500;
  cursor: pointer;
}

.book-chapter {
  font-size: 30px;
  color: #e0b048;
  font-weight: 500;
  cursor: pointer;
}

.book-verse {
  font-size: 30px;
  color: #e0b048;
  font-weight: 500;
  cursor: pointer;
}

.book-version {
  font-size: 30px;
  color: #e0b048;
  font-weight: 500;
  cursor: pointer;
}

.column-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  height: 400px;
  /* Adjust height as needed */
}

.selector-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.colon {
  font-size: 30px;
  font-weight: 500;
  color: #e0b048;
  margin-top: -15px;
}

.custom-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 20px;
}

.bible-word h3 {
  font-size: 20px;
  font-weight: bold;
}

/* Filter Modal CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f4f0db;
  /* Use the background color from .custom-container */
  padding: 30px;
  border-radius: 10px;
  max-width: 700px;
  /* Increased width for a more spacious look */
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #7a4b09;
  /* Use the color from .parallel-header h1 */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.modal-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Make dropdown container wider */
  color: #f1c053;
  background-color: #fff;
}

.modal-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* Make button take full width */
  justify-content: space-between;
  /* Space between text and caret */
  color: #7a4b09;
}

.modal-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
  width: 105%;
  /* Make dropdown menu take full width */
}

.modal-dropdown .dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-dropdown .dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.modal-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.modal-dropdown:hover .dropdown-menu {
  display: block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}

.btn {
  padding: 8px 16px;
  /* Reduced padding */
  font-size: 14px;
  /* Smaller font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-1 {
  background-color: #f1c053;
  color: #000;
}

.btn-1:hover {
  background-color: #e0b048;
}

.btn-2 {
  background-color: #ccc;
  color: #333;
}

.btn-2:hover {
  background-color: #b3b3b3;
}

.settings-icon {
  position: absolute;
  top: 200px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings-icon button {
  border: none;
  /* Remove the border */
  border-radius: 50%;
  /* Remove the corner border */
}

.prev,
.next {
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
}

.prev {
  left: 300px;
}

.next {
  right: 300px;
}

@media (max-width: 1200px) {
  .prev {
    left: 200px;
  }

  .next {
    right: 200px;
  }
}

@media (max-width: 992px) {
  .prev {
    left: 100px;
  }

  .next {
    right: 100px;
  }
}

@media (max-width: 768px) {
  .prev {
    left: 50px;
  }

  .next {
    right: 50px;
  }
}

@media (max-width: 576px) {

  .prev,
  .next {
    top: auto;
    bottom: 20px;
    left: 10px;
    right: 10px;
    justify-content: center;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}

.btn.dropdown-toggle.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bible-word {
  margin-top: -35px;
}

.left-column-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  color: #7a4b09;
  background-color: #fff;
}

.left-column-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  padding: 2px 5px;
  /* Adjusted padding */
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #7a4b09;
}

.left-column-dropdown .dropdown-toggle:hover {
  background-color: #e0d8c0;
}

.left-column-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 800px;
  width: 250px;
  overflow-y: auto;
  border-radius: 5px;
}

.left-column-dropdown .dropdown-search {
  padding: 2px;
  /* Adjusted padding */
  margin: 2px;
  /* Adjusted margin */
  width: calc(100% - 4px);
  /* Adjusted width */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.left-column-dropdown .dropdown-menu li {
  padding: 2px 5px;
  /* Adjusted padding */
  cursor: pointer;
}

.left-column-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.left-column-dropdown:hover .dropdown-menu {
  display: block;
}

.right-column-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  color: #7a4b09;
  background-color: #fff;
}

.right-column-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  padding: 2px 5px;
  /* Adjusted padding */
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #7a4b09;
}

.right-column-dropdown .dropdown-toggle:hover {
  background-color: #e0d8c0;
}

.right-column-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 800px;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
}

.right-column-dropdown .dropdown-search {
  padding: 2px;
  /* Adjusted padding */
  margin: 2px;
  /* Adjusted margin */
  width: calc(100% - 4px);
  /* Adjusted width */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.right-column-dropdown .dropdown-menu li {
  padding: 2px 5px;
  /* Adjusted padding */
  cursor: pointer;
}

.right-column-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.right-column-dropdown:hover .dropdown-menu {
  display: block;
}
</style>