<template>
  <div class="map-container">
    <h2 class="map-title">Abana</h2>
    <div ref="map" class="map" style="height: 500px; width: 100%;"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data() {
    return {
      center: { lat: 33.5133, lng: 36.3047 },
      zoom: 13,
      map: null,
      mapOptions: {
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: 'DEFAULT',
          position: 'TOP_RIGHT',
        },
        mapTypeId: 'satellite',
      },
      polylinePath: [
        { lat: 33.540154, lng: 36.239828 },
        { lat: 33.539979, lng: 36.239527 },
        { lat: 33.539875, lng: 36.239357 },
        { lat: 33.539654, lng: 36.239003 },
        { lat: 33.539496, lng: 36.238717 },
        { lat: 33.539246, lng: 36.238535 },
        { lat: 33.538942, lng: 36.238471 },
        { lat: 33.538727, lng: 36.238567 },
        { lat: 33.538495, lng: 36.238760 },
        { lat: 33.538289, lng: 36.238975 },
        { lat: 33.538128, lng: 36.239275 },
        { lat: 33.537967, lng: 36.239586 },
        { lat: 33.537797, lng: 36.239780 },
        { lat: 33.537582, lng: 36.239941 },
        { lat: 33.537323, lng: 36.240016 },
        { lat: 33.537091, lng: 36.239941 },
        { lat: 33.536742, lng: 36.239790 },
        { lat: 33.536518, lng: 36.239629 },
        { lat: 33.536357, lng: 36.239458 },
        { lat: 33.536223, lng: 36.239200 },
        { lat: 33.536071, lng: 36.239039 },
        { lat: 33.535901, lng: 36.238986 },
        { lat: 33.535669, lng: 36.239039 },
        { lat: 33.535329, lng: 36.239157 },
        { lat: 33.534372, lng: 36.239533 },
        { lat: 33.534050, lng: 36.239608 },
        { lat: 33.533809, lng: 36.239586 },
        { lat: 33.533585, lng: 36.239447 },
        { lat: 33.533370, lng: 36.239275 },
        { lat: 33.533183, lng: 36.239243 },
        { lat: 33.533084, lng: 36.239286 },
        { lat: 33.532914, lng: 36.239501 },
        { lat: 33.532700, lng: 36.239780 },
        { lat: 33.532524, lng: 36.240086 },
        { lat: 33.532073, lng: 36.239888 },
        { lat: 33.531737, lng: 36.239674 },
        { lat: 33.531164, lng: 36.239004 },
        { lat: 33.530928, lng: 36.238944 },
        { lat: 33.530592, lng: 36.239159 },
        { lat: 33.530406, lng: 36.239373 },
        { lat: 33.530091, lng: 36.239657 },
        { lat: 33.529748, lng: 36.239777 },
        { lat: 33.529497, lng: 36.239931 },
        { lat: 33.529404, lng: 36.240318 },
        { lat: 33.529139, lng: 36.240472 },
        { lat: 33.528972, lng: 36.241142 },
        { lat: 33.528492, lng: 36.241613 },
        { lat: 33.528372, lng: 36.242639 },
        { lat: 33.529984, lng: 36.244137 },
        { lat: 33.530246, lng: 36.244703 },
        { lat: 33.529322, lng: 36.246047 },
        { lat: 33.528185, lng: 36.248883 },
        { lat: 33.526170, lng: 36.250116 },
        { lat: 33.524072, lng: 36.248721 },
        { lat: 33.523181, lng: 36.248977 },
        { lat: 33.522267, lng: 36.249698 },
        { lat: 33.521477, lng: 36.251251 },
        { lat: 33.520864, lng: 36.255016 },
        { lat: 33.519641, lng: 36.257857 },
        { lat: 33.518975, lng: 36.258507 },
        { lat: 33.516744, lng: 36.259140 },
        { lat: 33.516044, lng: 36.260056 },
        { lat: 33.514843, lng: 36.260067 },
        { lat: 33.513891, lng: 36.261269 },
        { lat: 33.513869, lng: 36.262076 },
        { lat: 33.514979, lng: 36.262762 },
        { lat: 33.515014, lng: 36.263380 },
        { lat: 33.514685, lng: 36.263827 },
        { lat: 33.513998, lng: 36.263784 },
        { lat: 33.514893, lng: 36.265011 },
        { lat: 33.514585, lng: 36.265852 },
        { lat: 33.515115, lng: 36.265844 },
        { lat: 33.515200, lng: 36.266196 },
        { lat: 33.515079, lng: 36.266590 },
        { lat: 33.513540, lng: 36.267689 },
        { lat: 33.514685, lng: 36.269903 },
        { lat: 33.513905, lng: 36.274289 },
        { lat: 33.513769, lng: 36.274667 },
        { lat: 33.512669, lng: 36.275576 },
        { lat: 33.512424, lng: 36.276494 },
        { lat: 33.512946, lng: 36.279823 },
        { lat: 33.513488, lng: 36.293400 },
        { lat: 33.513365, lng: 36.295535 },
        { lat: 33.512775, lng: 36.298196 },
        { lat: 33.512810, lng: 36.301456 },
        { lat: 33.513137, lng: 36.302394 },
        { lat: 33.513062, lng: 36.303369 },
        { lat: 33.513550, lng: 36.304876 },
        { lat: 33.513651, lng: 36.306413 },
        { lat: 33.514059, lng: 36.307434 },
        { lat: 33.514014, lng: 36.309156 },
        { lat: 33.514351, lng: 36.309207 },
        { lat: 33.514312, lng: 36.310363 },
        { lat: 33.515061, lng: 36.313105 },
        { lat: 33.515032, lng: 36.314329 },
        { lat: 33.514067, lng: 36.315666 },
        { lat: 33.513858, lng: 36.316403 },
        { lat: 33.514109, lng: 36.316987 },
        { lat: 33.514984, lng: 36.317263 },
        { lat: 33.515635, lng: 36.318076 },
        { lat: 33.515404, lng: 36.319287 },
        { lat: 33.514325, lng: 36.321084 },
        { lat: 33.513810, lng: 36.321204 },
        { lat: 33.512679, lng: 36.322972 },
        { lat: 33.511032, lng: 36.324223 },
        { lat: 33.510406, lng: 36.325095 },
        { lat: 33.508015, lng: 36.325737 },
        { lat: 33.507443, lng: 36.326910 },
        { lat: 33.507885, lng: 36.329576 },
        { lat: 33.507562, lng: 36.331075 },
        { lat: 33.507722, lng: 36.332278 },
        { lat: 33.507075, lng: 36.332903 },
        { lat: 33.505273, lng: 36.337628 },
        { lat: 33.502827, lng: 36.340467 },
        { lat: 33.501632, lng: 36.341262 },
        { lat: 33.501282, lng: 36.342725 },
        { lat: 33.499640, lng: 36.345193 },
        { lat: 33.499551, lng: 36.347647 },
        { lat: 33.498667, lng: 36.350148 },
        { lat: 33.498922, lng: 36.354537 },
        { lat: 33.497816, lng: 36.355841 },
        { lat: 33.497736, lng: 36.356946 },
        { lat: 33.497095, lng: 36.357897 },
        { lat: 33.497548, lng: 36.358899 },
        { lat: 33.497183, lng: 36.359155 },
        { lat: 33.497149, lng: 36.359815 },
        { lat: 33.496714, lng: 36.360368 },
        { lat: 33.496919, lng: 36.360698 },
        { lat: 33.496819, lng: 36.362515 },
        { lat: 33.496304, lng: 36.363186 },
        { lat: 33.495170, lng: 36.363285 },
        { lat: 33.494785, lng: 36.363717 },
      ],
      markers: [
        { position: { lat: 33.5133, lng: 36.3047 }, description: "Abana" },
        { position: { lat: 31.75389, lng: 35.715 }, description: "Abarim" }

      ],
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyBxOtesHhrJD_mMU5RQspOftg5qO4LzevA",
      version: "weekly",
    });

    loader.load().then(() => {
      this.initMap();
    }).catch((error) => {
      console.error("Google Maps API failed to load:", error);
    });
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: this.zoom,
        mapTypeId: "satellite",
        tilt: 45,
        mapTypeControl: true,
        mapTypeControlOptions: {
          // eslint-disable-next-line no-undef
          style: google.maps.MapTypeControlStyle.DEFAULT,
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      });

      this.addMarkers();
      this.addPolyline();
    },
    addMarkers() {
      this.markers.forEach(markerData => {
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: markerData.position,
          map: this.map,
        });

        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow({
          content: `<b>${markerData.description}</b>`,
        });

        marker.addListener('mouseover', () => {
          infoWindow.open(this.map, marker);
        });

        marker.addListener('mouseout', () => {
          infoWindow.close();
        });
      });
    },
    addPolyline() {
      // eslint-disable-next-line no-undef
      const polyline = new google.maps.Polyline({
        path: this.polylinePath,
        geodesic: true,
        strokeColor: "#03396C",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      polyline.setMap(this.map);
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
}

.map-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 10px;
}
</style>
