<template>
  <div class="bible-verses d-flex flex-wrap">
    <p v-for="(verse, index) in verses" :key="index" @click="selectVerse(verse)" class="bible-verse">
      {{ verse }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    verses: Array,
  },
  methods: {
    selectVerse(verse) {
      this.$emit('verseSelected', verse);
    },
  },
};
</script>

<style scoped>
.bible-verse {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  font-size: 18px;
  text-decoration: underline;
  color: #7a4b09;
}

.bible-verse:hover {
  background-color: #f1c053;
}
</style>
