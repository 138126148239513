<template>
  <div class="verse-information" v-if="content">
    <h4 v-if="verse">{{ verse }}</h4>
    <p>{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    verse: String,
    content: String,
  },
};
</script>

<style scoped>
.verse-information {
  margin-top: 30px;
  font-size: 20px;
}
</style>
