<template>
    <NavbarComponent :forceFetch="forceFetch"/>
    <div class="container custom-container">
        <div class="success-container">
            <h1>Payment Success</h1>
            <div v-if="sessionDetails">
                <p><strong>Email:</strong> {{ sessionDetails.customer_details.email }}</p>
                <p><strong>Amount:</strong> {{ sessionDetails.amount_total / 100 }} {{
                    sessionDetails.currency.toUpperCase()
                    }}</p>
                <p><strong>Status:</strong> {{ sessionDetails.payment_status }}</p>
            </div>
            <div v-else>
                <p>Loading...</p>
            </div>
            <button @click="redirectToHome" class="home-button">Home</button>
        </div>
    </div>  
    <FooterComponent />
</template>

<script setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import api from '@/api';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const sessionDetails = ref(null);
const forceFetch = ref(false);
const router = useRouter();

const redirectToHome = () => {
  router.push('/home');
};

onMounted(async () => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');

    if (sessionId) {
        try {
            const response = await api.get(`/stripe/session/${sessionId}`);
            console.log(response)
            sessionDetails.value = response.data;
            forceFetch.value = true;
        } catch (err) {
            console.error(err);
        }
    }
});
</script>

<style scoped>
.custom-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 100%;
    background-color: #f4f0db;
    position: relative;
    margin-top: 100px;
}

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 5px;
}

.success-container h1 {
  color: #4caf50; /* Success green color */
}

.success-container p {
  font-size: 16px;
}

.home-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50; /* Success green color */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
</style>