<template>
  <div>
    <NavbarComponent />
    <div class="container-fluid">
      <div class="title text-center">
        <h1>KJV Hebrew-Greek Interlinear</h1>
      </div>
      <div class="settings-icon">
        <FilterIconComponent @click="handleFirstIconClick" />
      </div>
      <div class="nav-buttons">
        <div class="prev">
          <PreviousButton @previous="goToPreviousChapter" />
        </div>
        <div class="next">
          <NextButton @next="goToNextChapter" />
        </div>
      </div>
      <div class="row">
        <h2 class="book-version text-center" @click="handleFirstIconClick">{{ selectedVersion }}</h2>
        <h4 class="book-name text-center" @click="handleFirstIconClick">{{ selectedBook }}</h4>
        <h2 class="book-chapter text-center" @click="handleFirstIconClick">Chapter {{ selectedChapter }}</h2>
      </div>
      <VerseContentComponent @open-modal="openModal" />
      <ModalComponent :show="showModal" :word="selectedWord" @close="closeModal" />
    </div>

    <!-- Filter Modal -->
    <div v-if="showFilterModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h2>Filter Options</h2>
        <SearchBarComponent />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="bookDropdown">Book</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="bookDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedBook }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="bookDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="bookSearch"
                      placeholder="Search books" aria-label="Search">
                  </li>
                  <li v-for="book in filteredBooks" :key="book" @click="selectBook(book)">
                    <a class="dropdown-item" href="#">{{ book }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="chapterDropdown">Chapter</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="chapterDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedChapter }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="chapterDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="chapterSearch"
                      placeholder="Search chapters" aria-label="Search">
                  </li>
                  <li v-for="chapter in filteredChapters" :key="chapter" @click="selectChapter(chapter)">
                    <a class="dropdown-item" href="#">{{ chapter }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="versionDropdown">Version</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="versionDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedVersion }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="versionDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="versionSearch"
                      placeholder="Search versions" aria-label="Search">
                  </li>
                  <li v-for="version in filteredVersions" :key="version" @click="selectVersion(version)">
                    <a class="dropdown-item" href="#">{{ version }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="modal-buttons">
            <button @click="applyFilters" class="btn btn-1">Apply</button>
            <button @click="closeFilterModal" class="btn btn-2">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Filter Modal -->
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import FilterIconComponent from "@/components/FilterIconComponent.vue";
import VerseContentComponent from "@/components/eStudy/VerseContentComponent.vue";
import ModalComponent from "@/components/eStudy/ModalComponent.vue";
import PreviousButton from "@/components/eStudy/PreviousButton.vue";
import NextButton from "@/components/eStudy/NextButton.vue";
import SearchBarComponent from "@/components/eStudy/SearchBarComponent.vue";

export default {
  components: {
    NavbarComponent,
    FooterComponent,
    FilterIconComponent,
    VerseContentComponent,
    ModalComponent,
    PreviousButton,
    NextButton,
    SearchBarComponent,
  },
  data() {
    return {
      showModal: false,
      showFilterModal: false,
      selectedWord: "",
      books: [
        "Genesis",
        "Exodus",
        "Leviticus",
        "Numbers",
        "Deuteronomy",
        "Joshua",
        "Judges",
        "Ruth",
        "1 Samuel",
        "2 Samuel",
        "1 Kings",
        "2 Kings",
        "1 Chronicles",
        "2 Chronicles",
        "Ezra",
        "Nehemiah",
        "Esther",
        "Job",
        "Psalms",
        "Proverbs",
        "Ecclesiastes",
        "Song of Solomon",
        "Isaiah",
        "Jeremiah",
        "Lamentations",
        "Ezekiel",
        "Daniel",
        "Hosea",
        "Joel",
        "Amos",
        "Obadiah",
        "Jonah",
        "Micah",
        "Nahum",
        "Habakkuk",
        "Zephaniah",
        "Haggai",
        "Zechariah",
        "Malachi",
        "Matthew",
        "Mark",
        "Luke",
        "John",
        "Acts",
        "Romans",
        "1 Corinthians",
        "2 Corinthians",
        "Galatians",
        "Ephesians",
        "Philippians",
        "Colossians",
        "1 Thessalonians",
        "2 Thessalonians",
        "1 Timothy",
        "2 Timothy",
        "Titus",
        "Philemon",
        "Hebrews",
        "James",
        "1 Peter",
        "2 Peter",
        "1 John",
        "2 John",
        "3 John",
        "Jude",
        "Revelation",
      ],
      chapters: Array.from({ length: 50 }, (_, i) => i + 1),
      versions: ["KJV", "NIV", "ESV", "NKJV", "NLT"],
      selectedBook: "Genesis",
      selectedChapter: 1,
      selectedVersion: "KJV",
      filterSelectedBook: "Genesis",
      filterSelectedChapter: 1,
      filterSelectedVersion: "KJV",
      bookSearch: "",
      chapterSearch: "",
      versionSearch: "",
    };
  },
  computed: {
    filteredBooks() {
      return this.books.filter(book => book.toLowerCase().includes(this.bookSearch.toLowerCase()));
    },
    filteredChapters() {
      return this.chapters.filter(chapter => chapter.toString().includes(this.chapterSearch));
    },
    filteredVersions() {
      return this.versions.filter(version => version.toLowerCase().includes(this.versionSearch.toLowerCase()));
    },
  },
  methods: {
    handleFirstIconClick() {
      this.filterSelectedBook = this.selectedBook;
      this.filterSelectedChapter = this.selectedChapter;
      this.filterSelectedVersion = this.selectedVersion;
      this.showFilterModal = true;
    },
    closeModal() {
      this.showFilterModal = false;
      this.showModal = false;
    },
    applyFilters() {
      this.selectedBook = this.filterSelectedBook;
      this.selectedChapter = this.filterSelectedChapter;
      this.selectedVersion = this.filterSelectedVersion;
      this.closeFilterModal();
    },
    selectBook(book) {
      this.filterSelectedBook = book;
      this.bookSearch = ""; // Clear search input after selection
    },
    selectChapter(chapter) {
      this.filterSelectedChapter = chapter;
      this.chapterSearch = ""; // Clear search input after selection
    },
    selectVersion(version) {
      this.filterSelectedVersion = version;
      this.versionSearch = ""; // Clear search input after selection
    },
    closeFilterModal() {
      this.showFilterModal = false;
    },
    openModal(word) {
      this.selectedWord = word;
      this.showModal = true;
    },
    goToPreviousChapter() {
      if (this.selectedChapter > 1) {
        this.selectedChapter--;
      } else {
        const bookIndex = this.books.indexOf(this.selectedBook);
        if (bookIndex > 0) {
          this.selectedBook = this.books[bookIndex - 1];
          this.selectedChapter = this.chapters[this.chapters.length - 1];
        }
      }
    },
    goToNextChapter() {
      if (this.selectedChapter < this.chapters.length) {
        this.selectedChapter++;
      } else {
        const bookIndex = this.books.indexOf(this.selectedBook);
        if (bookIndex < this.books.length - 1) {
          this.selectedBook = this.books[bookIndex + 1];
          this.selectedChapter = 1;
        }
      }
    },
  },
};
</script>



<style scoped>
body {
  background: #f4f0db;
}

.container-fluid {
  margin-top: 100px;
  height: 100%;
  background: #f4f0db;
  padding: 0 300px 10px;
}

.title h1 {
  font-size: 50px;
  font-weight: bold;
  color: #7a4b09;
  padding-bottom: 0;
  padding-top: 45px;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.search-icon {
  font-size: 1.2em;
  color: #000;
  cursor: pointer;
}

.search-bar {
  display: flex;
  max-width: 440px;
  padding-bottom: 20px;
}

.search-bar .btn {
  margin-left: 16px;
}

.book-version {
  font-size: 30px;
  color: #7a4b09;
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
}

.book-name {
  font-size: 30px;
  color: #7a4b09;
  font-weight: 500;
  cursor: pointer;
}

.book-chapter {
  font-size: 40px;
  color: #7a4b09;
  font-weight: 700;
  margin-bottom: 24px;
  cursor: pointer;
}

.verse-content {
  font-size: 20px;
  color: #000;
  text-align: left;
  margin: 0 30px;
}

.verse-content .word {
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}

.verse-content .word:hover {
  color: #007bff;
}

.settings-icon {
  position: absolute;
  top: 200px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings-icon button {
  border: none;
  /* Remove the border */
  border-radius: 50%;
  /* Remove the corner border */
}

/* Filter Modal CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f4f0db;
  /* Use the background color from .custom-container */
  padding: 30px;
  border-radius: 10px;
  max-width: 700px;
  /* Increased width for a more spacious look */
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #7a4b09;
  /* Use the color from .parallel-header h1 */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.modal-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Make dropdown container wider */
  color: #f1c053;
  background-color: #fff;
}

.modal-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* Make button take full width */
  justify-content: space-between;
  /* Space between text and caret */
  color: #7a4b09;
}

.modal-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
  width: 105%;
  /* Make dropdown menu take full width */
}

.modal-dropdown .dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-dropdown .dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.modal-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.modal-dropdown:hover .dropdown-menu {
  display: block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  /* Reduced padding */
  font-size: 14px;
  /* Smaller font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-1 {
  background-color: #f1c053;
  color: #000;
}

.btn-1:hover {
  background-color: #e0b048;
}

.btn-2 {
  background-color: #ccc;
  color: #333;
}

.btn-2:hover {
  background-color: #b3b3b3;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}

.prev,
.next {
  position: absolute;
  top: 230px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
}

.prev {
  left: 300px;
}

.next {
  right: 300px;
}

@media (max-width: 1200px) {
  .prev {
    left: 200px;
  }

  .next {
    right: 200px;
  }
}

@media (max-width: 992px) {
  .prev {
    left: 100px;
  }

  .next {
    right: 100px;
  }
}

@media (max-width: 768px) {
  .prev {
    left: 50px;
  }

  .next {
    right: 50px;
  }
}

@media (max-width: 576px) {

  .prev,
  .next {
    top: auto;
    bottom: 20px;
    left: 10px;
    right: 10px;
    justify-content: center;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}
</style>
