<template>
  <NavbarComponent />
  <div class="add-info-container">
    <div class="title text-center">
      <h1>KJV Hebrew-Greek Interlinear</h1>
      <h2 class="subtitle">"Beginning"</h2>
    </div>
    <div class="content text-center">
      <h1>h1</h1>
      <h2>h2</h2>
      <h3>h3</h3>
      <h4>h4</h4>
      <h5>h5</h5>
      <h6>h6</h6>
      <br>
      <p><strong>Actual Word</strong> = רֵאשִׁית</p>
      <p><strong>Definition</strong> = </p>
      <p><strong>Original</strong>: רֵאשִׁית</p>
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import NavbarComponent from '../NavbarComponent.vue';
import FooterComponent from '../FooterComponent.vue';

export default {
  components: {
    NavbarComponent,
    FooterComponent
  },
  name: 'AdditionalInfoComponent'
};
</script>

<style scoped>
.add-info-container {
  padding: 40px;
  padding-top: 80px; /* Added padding to the top */
  height: 100%;
  width: 100%;
  background: #f4f0db;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 36px;
  font-weight: 600;
  color: #7a4b09;
  margin: 20px 0;
}

.subtitle {
  font-size: 24px;
  color: #7a4b09;
  margin-top: 10px;
}

.content {
  width: 80%;
  max-width: 800px;
  margin: 20px 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
}

p {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}
</style>