<template>
  <NavbarComponent />
  <div class="container custom-container">
    <div class="settings-icons">
      <FilterIconComponent @click="handleFirstIconClick" />
      <div class="layer-icon" @click="handleSecondIconClick">
        <i class="bi bi-layers-fill"></i>
      </div>
    </div>
    
    <div class="parallel-container">
      <div class="nav-buttons">
        <div class="prev">
          <PreviousButton @previous="handlePrevPageClick" v-if="selectedChapter > 1"/>
        </div>
        <div class="next">
          <NextButton @next="handleNextPageClick" v-if="selectedChapter < numberOfChapters"/>
        </div>
      </div>
      <div class="parallel-header text-center my-4">
        <h1>Parallel: Side-by-Side Bible</h1>
      </div>
      <nav class="navbar navbar-expand-lg justify-content-center">
        <ul class="nav justify-content-center">
          <li class="dropdown">
            <div class="dropdown-toggle">
              <h1 id="selected-book">{{ selectedBook.name }}</h1>
            </div>
            <ul class="dropdown-menu">
              <li v-for="book in books" :key="book" @click="bookChange(book)">{{ book.name }}</li>
            </ul>
          </li>
          <li class="dropdown">
            <div class="dropdown-toggle">
              <h1 id="selected-chapter">{{ selectedChapter }}</h1>
            </div>
            <ul class="dropdown-menu">
              <li v-for="chapter in numberOfChapters" :key="chapter" @click="chapterChange(chapter)">{{ chapter }}</li>
            </ul>
          </li>
        </ul>
      </nav>

      <div class="row justify-content-center">
        <div class="col-md-4 col-lg-4" v-for="(viewer, index) in viewers" :key="`viewer-${index}`"
          v-show="viewer.visible">
          <div class="viewer">
            <!-- <select class="form-select form-select-sm bible-viewer version-dropdown"
              v-model="selections[index].version"> -->
            <select class="form-select form-select-sm bible-viewer version-dropdown" v-model="viewer.selectedVersion"
              @change="versionChange(viewer.selectedVersion, index)">
              <option v-for="version in versions" :key="version.short_name" :value="version.short_name">
                {{ version.short_name }}
              </option>
            </select>
            <div class="spinner" v-if="viewer.loading">Loading...</div>
            <div class="content-section" v-else-if="!viewer.verses.length">
              <p>Sorry, book of {{ selectedBook.name }} is not available in this version.</p>
            </div>
            <div class="content-section" v-else v-for="verse in viewer.verses" :key="verse.id">
              <sup>{{ verse.verse_number }}</sup> <span v-html="verse.verse_content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showFilterModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h2>Filter Options</h2>
        <div class="form-group">
          <label for="bookDropdown">Book</label>
          <select id="bookDropdown" v-model="filterBook.name" class="form-control" @change="filterBookChange">
            <option v-for="book in books" :key="book">{{ book.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="chapterDropdown">Chapter</label>
          <select id="chapterDropdown" v-model="filterChapter" class="form-control">
            <option v-for="chapter in numberOfChapters" :key="chapter">{{ chapter }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="viewer1Dropdown">Viewer 1</label>
          <select id="viewer1Dropdown" v-model="filterVersion1" class="form-control">
            <option v-for="version in versions" :key="version">{{ version.short_name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="viewer2Dropdown">Viewer 2</label>
          <select id="viewer2Dropdown" v-model="filterVersion2" class="form-control">
            <option v-for="version in versions" :key="version">{{ version.short_name }}</option>
          </select>
        </div>
        <div class="form-group" v-if="viewers[2].visible === true">
          <label for="viewer3Dropdown">Viewer 3</label>
          <select id="viewer3Dropdown" v-model="filterVersion3" class="form-control">
            <option v-for="version in versions" :key="version">{{ version.short_name }}</option>
          </select>
        </div>
        <button @click="applyFilters" class="btn btn-1 btn-primary">Apply</button>
        <button @click="closeModal" class="btn btn-2 btn-secondary">Cancel</button>
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import FilterIconComponent from "@/components/FilterIconComponent.vue";
import api from "@/api";
import PreviousButton from "@/components/eStudy/PreviousButton.vue";
import NextButton from "@/components/eStudy/NextButton.vue";

export default {
  async mounted() {
    await this.fetchVersions();
    await this.fetchBooks();
    await this.fetchVersesForAll();
  },

  components: {
    NavbarComponent,
    FooterComponent,
    FilterIconComponent,
    PreviousButton,
    NextButton,
  },
  data() {
    return {
      showFilterModal: false,
      versions: [],
      books: [],
      viewerOptions: ["English", "French", "Spanish", "German"],

      selectedBook: { bookid: 1, name: "Genesis" },
      selectedChapter: 1,
      numberOfChapters: 50,

      filterBook: { bookid: 1, name: "Genesis" },
      filterChapter: 1,
      filterVersion1: "KJV",
      filterVersion2: "KJV",
      filterVersion3: "KJV",

      viewers: [
        { verses: null, selectedVersion: "KJV", visible: true, loading: true },
        { verses: null, selectedVersion: "KJV", visible: true, loading: true },
        { verses: null, selectedVersion: "KJV", visible: true, loading: true }
      ],
      layerIcon1: require("@/assets/images/add-layer.png"), // replace with your actual image path
      layerIcon2: require("@/assets/images/minus-layer.png"), // replace with your actual image path
      currentLayerIcon: require("@/assets/images/add-layer.png"), // set the initial image
      bookSearch: "",
      chapterSearch: "",
      versionSearch: "",
      viewerSearch1: "",
      viewerSearch2: "",
      viewerSearch3: "",
    };
  },
  computed: {
    filteredBooks() {
      return this.books.filter((book) =>
        book.toLowerCase().includes(this.bookSearch.toLowerCase())
      );
    },
    filteredChapters() {
      return this.chapters.filter((chapter) =>
        chapter.toString().includes(this.chapterSearch)
      );
    },
    filteredViewers1() {
      return this.viewerOptions.filter((viewer) =>
        viewer.toLowerCase().includes(this.viewerSearch1.toLowerCase())
      );
    },
    filteredViewers2() {
      return this.viewerOptions.filter((viewer) =>
        viewer.toLowerCase().includes(this.viewerSearch2.toLowerCase())
      );
    },
    filteredViewers3() {
      return this.viewerOptions.filter((viewer) =>
        viewer.toLowerCase().includes(this.viewerSearch3.toLowerCase())
      );
    },
    filteredViewerOptions() {
      return this.viewerOptions.filter(option =>
        option.toLowerCase().includes(this.versionSearch.toLowerCase())
      );
    },
  },
  methods: {
    //when a new book is selected
    bookChange(book) {
      this.selectedBook = book;
      this.filterBook = { ...book };
      this.selectedChapter = 1;
      this.filterChapter = 1;
      this.numberOfChapters = parseInt(book.chapters)
      //this.fetchNumberOfChapters(book.bookid);
      this.fetchVersesForAll();
    },
    //when a new book is selected
    filterBookChange() {
      const filteredBook = this.books.find(book => book.name === this.filterBook.name);
      console.log(filteredBook);
      console.log(this.filterBook);
      if (filteredBook) {
        this.filterBook.name = filteredBook.name;
        this.filterBook.bookid = filteredBook.bookid; 
        this.filterBook.chapters = filteredBook.chapters;

        this.filterChapter = 1;
        this.numberOfChapters = parseInt(filteredBook.chapters);
      }
    },
    //when a new chapter is selected
    chapterChange(chapter) {
      this.selectedChapter = chapter;
      this.filterChapter = chapter;
      this.fetchVersesForAll();
    },
    //when a new version is selected
    versionChange(version, index) {
      this[`selectedVersion${index}`] = version;
      this[`filterVersion${index+1}`] = version;
      this.fetchVerseForViewer(index);
    },

    //fetches all versions available in the database
    async fetchVersions() {
      try {
        const response = await api.get('/get-versions');
        this.versions = response.data;
      } catch (error) {
        console.error("Failed to fetch versions:", error);
      }
    },

    //fetches all books available in the database
    async fetchBooks() {
      try {
        const response = await api.get(`/get-books-version/KJV`);
        this.books = response.data;
      } catch (error) {
        console.error("Failed to fetch books:", error);
      }
    },

    //fetches the number of chapters in the book passed
    async fetchNumberOfChapters(bookid) {
      try {
        const response = await api.get(`/get-number-of-chapters/KJV/${bookid}`);
        this.numberOfChapters = parseInt(response.data);
      } catch (error) {
        console.error("Failed to fetch number of chapters:", error);
      }
    },

    //fetches the verses for the selected version, book, and chapter
    async fetchVerses(viewer, viewerIndex) {

      if (viewer.selectedVersion && this.selectedBook.bookid && this.selectedChapter) {
        try {
          this.viewers[viewerIndex].loading = true;
          const response = await api.get(`/get-verses/${viewer.selectedVersion}/${this.selectedBook.bookid}/${this.selectedChapter}`);
          this.viewers[viewerIndex].verses = response.data;

        } catch (error) {
          console.error("Failed to fetch verses for viewer", viewerIndex, ":", error);
        } finally {
          this.viewers[viewerIndex].loading = false;
        }

      }
    },

    //fetches verses for all viewers
    fetchVersesForAll() {
      this.viewers.forEach((viewer, index) => {
        this.fetchVerses(viewer, index);
      });
    },

    //fetches verses for the selected viewer using index
    fetchVerseForViewer(index) {
      const selectedViewer = this.viewers[index]
      this.fetchVerses(selectedViewer, index);
    },

    handleFirstIconClick() {
      this.showFilterModal = true;
    },
    handleSecondIconClick() {
      this.viewers[2].visible = !this.viewers[2].visible;
      this.currentLayerIcon =
        this.currentLayerIcon === this.layerIcon1 ? this.layerIcon2 : this.layerIcon1;
    },
    closeModal() {
      this.showFilterModal = false;
    },
    applyFilters() {
      this.selectedBook = { ...this.filterBook };
      this.selectedChapter = this.filterChapter;

      this.viewers[0].selectedVersion = this.filterVersion1;
      this.viewers[1].selectedVersion = this.filterVersion2;
      this.viewers[2].selectedVersion = this.filterVersion3;

      this.fetchVersesForAll();

      this.showFilterModal = false;
    },

    handleNextPageClick() {
      if (this.selectedChapter < this.numberOfChapters) {
        this.selectedChapter++;
        this.fetchVersesForAll();
      }
    },
    handlePrevPageClick() {
      if (this.selectedChapter > 1) {
        this.selectedChapter--;
        this.fetchVersesForAll();
      }
    },
  },


};
</script>

<style scoped>
.custom-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 100%;
  background-color: #f4f0db;
  position: relative;
  margin-top: 100px;
}

.settings-icons {
  position: absolute;
  top: 100px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-icon,
.layer-icon,
.next-icon,
.prev-icon {
  width: 40px;
  height: 40px;
  background-color: #f1c053;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.book-name {
  font-size: 30px;
  color: #7a4b09;
  font-weight: 500;
  cursor: pointer;
}

.book-chapter {
  font-size: 40px;
  color: #7a4b09;
  font-weight: 700;
  margin-bottom: 24px;
  cursor: pointer;
}

.layer-icon img {
  width: 20px;
  height: 25px;
  border-radius: 50%;
}

.layer-icon img:hover {
  background-color: #f1c053;
}

.parallel-container {
  margin-top: 10px;
}

.parallel-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallel-header h1 {
  font-size: 50px;
  font-weight: bold;
  color: #7a4b09;
}

.nav {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-toggle i {
  visibility: hidden;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1c053;
  color: #000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

ul.dropdown-menu {
  width: 300px;
}

.navbar h1 {
  font-size: 25px;
  color: #7a4b09;
}

.navbar .nav-link {
  color: #7a4b09;
  font-weight: 600;
}

.navbar .nav-link:hover {
  color: #7a4b09;
}

.viewer {
  background-color: #f4f0db;
  padding: 20px;
  margin: 10px 5px;
}

.version-dropdown {
  background-color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.content-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.content-section h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #7a4b09;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f4f0db;
  /* Use the background color from .custom-container */
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  /* Increased width for a more spacious look */
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #7a4b09;
  /* Use the color from .parallel-header h1 */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.modal-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Make dropdown container wider */
  color: #f1c053;
  background-color: #fff;
}

.modal-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* Make button take full width */
  justify-content: space-between;
  /* Space between text and caret */
  color: #7a4b09;
}

.modal-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
  width: 105%;
  /* Make dropdown menu take full width */
}

.modal-dropdown .dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-dropdown .dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.modal-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.modal-dropdown:hover .dropdown-menu {
  display: block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  /* Reduced padding */
  font-size: 14px;
  /* Smaller font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-1 {
  background-color: #f1c053;
  color: #000;
}

.btn-1:hover {
  background-color: #e0b048;
}

.btn-2 {
  background-color: #ccc;
  color: #333;
}

.btn-2:hover {
  background-color: #b3b3b3;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.viewer-column:nth-child(1) {
  padding-left: 20px;
}

.viewer-column:nth-child(3) {
  padding-right: 20px;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.prev,
.next {
  position: absolute;
  top: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
}

.prev {
  left: 300px;
}

.next {
  right: 300px;
}

@media (max-width: 1200px) {
  .prev {
    left: 200px;
  }

  .next {
    right: 200px;
  }
}

@media (max-width: 992px) {
  .prev {
    left: 100px;
  }

  .next {
    right: 100px;
  }
}

@media (max-width: 768px) {
  .prev {
    left: 50px;
  }

  .next {
    right: 50px;
  }
}

@media (max-width: 576px) {

  .prev,
  .next {
    top: auto;
    bottom: 20px;
    left: 10px;
    right: 10px;
    justify-content: center;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}


.custom-select {
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #000;
  width: 100%;
  box-sizing: border-box;
}

.custom-select option:hover {
  background-color: #f1c053;
  color: #000;
}

.custom-select option:hover {
  background-color: #f1c053;
  color: #000;
}
</style>
