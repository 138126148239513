<template>
  <div>
    <NavbarComponent />
    <div class="container-fluid main-container">
      <h1 class="title text-center">Bible Atlas</h1>
      <div class="settings-icon">
        <FilterIconComponent @click="handleFirstIconClick" />
      </div>

      <div class="row">
        <h2 class="book-name text-center" @click="handleFirstIconClick">{{ selectedBook }}</h2>
      </div>

      <!-- Map and Icons Container -->
      <div class="map-icons-container row mx-5">
        <div class="col-9">
          <div id="map" class="map-display">
            <MapComponent />
          </div>
        </div>
        <div class="col-3">
          <transition name="fade">
            <div v-if="selectedIcon" class="icon-info-container">
              <button class="close-btn" @click="deselectIcon">&times;</button>
              <div class="selected-icon">
                <img :src="selectedIcon.src" alt="Selected icon" class="selected-icon-image" />
                <h3>{{ selectedIcon.name }}</h3>
              </div>
              <p class="icon-description">{{ selectedIcon.description }}</p>
              <h4 class="notable-places-title">Notable {{ selectedIcon.name }}s in the Bible:</h4>
              <ul class="places-list">
                <li v-for="place in selectedIcon.places.slice(0, 5)" :key="place">{{ place }}</li>
              </ul>
            </div>
            <div v-else class="place-types">
              <div class="icon-grid">
                <div class="icon-container" v-for="icon in icons" :key="icon.id" @click="selectIcon(icon)">
                  <a :href="icon.url" target="_blank" class="icon-link">
                    <img :src="icon.src" alt="icon" class="icon-image" />
                  </a>
                  <div class="icon-name">{{ icon.name }}</div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- End of Map and Icons Container -->

      <div v-if="selectedIcon" class="bottom-icons-container">
        <div class="icon-container" v-for="icon in unselectedIcons" :key="icon.id" @click="selectIcon(icon)">
          <a :href="icon.url" target="_blank" class="icon-link">
            <img :src="icon.src" alt="icon" class="icon-image" />
          </a>
          <div class="icon-name">{{ icon.name }}</div>
        </div>
      </div>

      <!-- Definition Container -->
      <div class="definition-container mt-5">
        <h2 class="definition-title text-center">Abana</h2>
        <p class="sub-header text-center">(Abana, Abanah)</p>
        <p class="definition-text">
          AB'ANA, a river, now Barada "cold" whose sources are in the mountains of
          Lebanon, 24 ms. n. w. of Damascus. From one spring, at Zebedani, 3610 ft. above
          the sea, it descends 1350 ft. to the city through which it runs and continues 15
          ms. e. to a marshy lake. There are at least 43 villages upon the main stream e.
          of the city and upon its canals, and it waters, or enriches, a bout 320 sq. ms.
          of country. The only reference is in 2 Kings 5:12.
        </p>
        <h5 class="occurences">
          Occurences: <span class="strongs-hebrew">Strong's Hebrew: H71</span>
        </h5>
        <h3>2 Kings 5:12</h3>
        <p class="definition-text indent">
          Are not Abana and Pharpar, rivers of Damascus, better than all the waters of
          Israel? may I not wash in them, and be clean? So he turned and went away in a
          rage.
        </p>
      </div>
      <!-- End of Definition Container -->
    </div>

    <div v-if="showFilterModal" class="modal-overlay" @click.self="closeFilterModal">
      <div class="modal-content">
        <h2>Filter Options</h2>
        <SearchBarComponent />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="bookDropdown">Book</label>
              <div class="modal-dropdown">
                <button class="btn dropdown-toggle" type="button" id="bookDropdownButton" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ filterSelectedBook }}
                </button>
                <ul class="dropdown-menu p-3" aria-labelledby="bookDropdownButton">
                  <li>
                    <input class="form-control me-2 dropdown-search" type="search" v-model="bookSearch"
                      placeholder="Search books" aria-label="Search">
                  </li>
                  <li v-for="book in filteredBooks" :key="book" @click="selectBook(book)">
                    <a class="dropdown-item" href="#">{{ book }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="modal-buttons">
            <button @click="applyFilters" class="btn btn-1">Apply</button>
            <button @click="closeFilterModal" class="btn btn-2">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import SearchBarComponent from "@/components/eStudy/SearchBarComponent.vue";
import MapComponent from "@/components/eStudy/MapComponent.vue";
import FilterIconComponent from "@/components/FilterIconComponent.vue";

import all from "@/assets/images/bible-atlas/icon-all.png";
import rivericon from "@/assets/images/bible-atlas/icon-river.png";
import mountain from "@/assets/images/bible-atlas/icon-mountain.png";
import settlement from "@/assets/images/bible-atlas/icon-settlement.png";
import region from "@/assets/images/bible-atlas/icon-region.png";
import campsite from "@/assets/images/bible-atlas/icon-campsite.png";
import people from "@/assets/images/bible-atlas/icon-people group.png";
import road from "@/assets/images/bible-atlas/icon-road.png";
import field from "@/assets/images/bible-atlas/icon-field.png";
import tree from "@/assets/images/bible-atlas/icon-tree.png";
import hill from "@/assets/images/bible-atlas/icon-hill.png";
import water from "@/assets/images/bible-atlas/icon-body of water.png";
import altar from "@/assets/images/bible-atlas/icon-altar.png";
import gate from "@/assets/images/bible-atlas/icon-gate.png";
import city from "@/assets/images/bible-atlas/icon-city.png";
import well from "@/assets/images/bible-atlas/icon-well.png";
import pool from "@/assets/images/bible-atlas/icon-pool.png";
import cliff from "@/assets/images/bible-atlas/icon-cliff.png";
import island from "@/assets/images/bible-atlas/icon-island.png";
import spring from "@/assets/images/bible-atlas/icon-spring.png";
import natural from "@/assets/images/bible-atlas/icon-natural area.png";
import valley from "@/assets/images/bible-atlas/icon-valley.png";
import wadi from "@/assets/images/bible-atlas/icon-wadi.png";
import hall from "@/assets/images/bible-atlas/icon-hall.png";
import forest from "@/assets/images/bible-atlas/icon-forest.png";
import room from "@/assets/images/bible-atlas/icon-room.png";
import district from "@/assets/images/bible-atlas/icon-district in settlement.png";

export default {
  components: {
    NavbarComponent,
    FooterComponent,
    SearchBarComponent,
    MapComponent,
    FilterIconComponent,
  },
  data() {
    return {
      books: ["Genesis",
        "Exodus",
        "Leviticus",
        "Numbers",
        "Deuteronomy",
        "Joshua",
        "Judges",
        "Ruth",
        "1 Samuel",
        "2 Samuel",
        "1 Kings",
        "2 Kings",
        "1 Chronicles",
        "2 Chronicles",
        "Ezra",
        "Nehemiah",
        "Esther",
        "Job",
        "Psalms",
        "Proverbs",
        "Ecclesiastes",
        "Song of Solomon",
        "Isaiah",
        "Jeremiah",
        "Lamentations",
        "Ezekiel",
        "Daniel",
        "Hosea",
        "Joel",
        "Amos",
        "Obadiah",
        "Jonah",
        "Micah",
        "Nahum",
        "Habakkuk",
        "Zephaniah",
        "Haggai",
        "Zechariah",
        "Malachi",
        "Matthew",
        "Mark",
        "Luke",
        "John",
        "Acts",
        "Romans",
        "1 Corinthians",
        "2 Corinthians",
        "Galatians",
        "Ephesians",
        "Philippians",
        "Colossians",
        "1 Thessalonians",
        "2 Thessalonians",
        "1 Timothy",
        "2 Timothy",
        "Titus",
        "Philemon",
        "Hebrews",
        "James",
        "1 Peter",
        "2 Peter",
        "1 John",
        "2 John",
        "3 John",
        "Jude",
        "Revelation",],
      chapters: Array.from({ length: 50 }, (_, i) => i + 1),
      selectedBook: "Genesis",
      icons: [
        { id: 1, src: all, name: "All", description: "Shows all available icons on the map.", places: ["Jerusalem", "Bethlehem", "Nazareth", "Jericho", "Capernaum"] },
        { id: 2, src: rivericon, name: "River", description: "Represents rivers and streams mentioned in the Bible.", places: ["Jordan", "Nile", "Euphrates", "Tigris", "Jabbok"] },
        { id: 3, src: mountain, name: "Mountain", description: "Indicates important mountains and hills in biblical narratives.", places: ["Mount Sinai", "Mount Zion", "Mount of Olives", "Mount Carmel", "Mount Ararat"] },
        { id: 4, src: settlement, name: "Settlement", description: "Represents settlements and cities mentioned in the Bible.", places: ["Jerusalem", "Bethlehem", "Nazareth", "Jericho", "Capernaum"] },
        { id: 5, src: region, name: "Region", description: "Shows regions and areas mentioned in the Bible.", places: ["Canaan", "Egypt", "Babylon", "Assyria", "Persia"] },
        { id: 6, src: campsite, name: "Campsite", description: "Indicates campsites and encampments mentioned in the Bible.", places: ["Kadesh", "Shittim", "Dothan", "Edom", "Moab"] },
        { id: 7, src: people, name: "People", description: "Represents people and groups mentioned in the Bible.", places: ["Israelites", "Egyptians", "Babylonians", "Assyrians", "Persians"] },
        { id: 8, src: water, name: "Body of Water", description: "Represents bodies of water mentioned in the Bible.", places: ["Red Sea", "Dead Sea", "Sea of Galilee", "Sea of Tiberias", "Sea of Reeds"] },
        { id: 9, src: road, name: "Road", description: "Shows roads and paths mentioned in the Bible.", places: ["Road to Egypt", "Road to Canaan", "Road to Babylon", "Road to Assyria", "Road to Persia"] },
        { id: 10, src: field, name: "Field", description: "Represents fields and agricultural areas mentioned in the Bible.", places: ["Field of Boaz", "Field of Ephraim", "Field of Araunah", "Field of the Giants", "Field of Zebulun"] },
        { id: 11, src: tree, name: "Tree", description: "Indicates trees and forests mentioned in the Bible.", places: ["Oak of Mamre", "Olive Tree of Jericho", "Cedar of Lebanon", "Tree of Knowledge of Good and Evil", "Tree of Life"] },
        { id: 12, src: hill, name: "Hill", description: "Represents hills and mountains mentioned in the Bible.", places: ["Mount Sinai", "Mount Zion", "Mount of Olives", "Mount Carmel", "Mount Ararat"] },
        { id: 13, src: altar, name: "Altar", description: "Shows altars and sacred sites mentioned in the Bible.", places: ["Altar of Burnt Offering", "Altar of Incense", "Altar of the Covenant", "Altar of Peace", "Altar of the Golden Calf"] },
        { id: 14, src: gate, name: "Gate", description: "Represents gates and entrances mentioned in the Bible.", places: ["Gate of the City of David", "Gate of the New City", "Gate of the Outer Court", "Gate of the Inner Court", "Gate of the Water Gate"] },
        { id: 15, src: city, name: "City", description: "Indicates cities and towns mentioned in the Bible.", places: ["Jerusalem", "Bethlehem", "Nazareth", "Jericho", "Capernaum"] },
        { id: 16, src: well, name: "Well", description: "Represents wells and water sources mentioned in the Bible.", places: ["Well of Jacob's Ladder", "Well of Samaria", "Well of Lahai-roi", "Well of the Woman at the Well", "Well of the Prophets"] },
        { id: 17, src: pool, name: "Pool", description: "Shows pools and bodies of water mentioned in the Bible.", places: ["Pool of Bethesda", "Pool of Siloam", "Pool of Shiloah", "Pool of Gibeon", "Pool of Hezekiah"] },
        { id: 18, src: cliff, name: "Cliff", description: "Represents cliffs and steep slopes mentioned in the Bible.", places: ["Cliff of the Red Heifer", "Cliff of the Valley of Achor", "Cliff of the Valley of Hinnom", "Cliff of the Valley of Elah", "Cliff of the Valley of Jehoshaphat"] },
        { id: 19, src: island, name: "Island", description: "Indicates islands and islands mentioned in the Bible.", places: ["Island of Pharos", "Island of Cyprus", "Island of Crete", "Island of Malta", "Island of Delos"] },
        { id: 20, src: spring, name: "Spring", description: "Represents springs and water sources mentioned in the Bible.", places: ["Spring of the Rock", "Spring of the Serpent", "Spring of the Angel", "Spring of the Sheep", "Spring of the Fountain"] },
        { id: 21, src: natural, name: "Natural Area", description: "Shows natural areas and landscapes mentioned in the Bible.", places: ["Garden of Eden", "Garden of Gethsemane", "Garden of Hesed", "Garden of the Lord", "Garden of the Valley of Josaphat"] },
        { id: 22, src: valley, name: "Valley", description: "Represents valleys and lowlands mentioned in the Bible.", places: ["Valley of Achor", "Valley of Hinnom", "Valley of Elah", "Valley of Jehoshaphat", "Valley of Baca"] },
        { id: 23, src: wadi, name: "Wadi", description: "Indicates wadis and dry riverbeds mentioned in the Bible.", places: ["Wadi Arnon", "Wadi Kelt", "Wadi Mujib", "Wadi Hanif", "Wadi Bani Khalid"] },
        { id: 24, src: hall, name: "Hall", description: "Shows halls and buildings mentioned in the Bible.", places: ["Hall of the Pillars", "Hall of the Kings", "Hall of the Chariots", "Hall of the Treasury", "Hall of the Bulls"] },
        { id: 25, src: forest, name: "Forest", description: "Represents forests and wooded areas mentioned in the Bible.", places: ["Forest of Lebanon", "Forest of Eden", "Forest of Hazor", "Forest of Dan", "Forest of the Philistines"] },
        { id: 26, src: room, name: "Room", description: "Indicates rooms and structures mentioned in the Bible.", places: ["Room of the High Priest", "Room of the Sanhedrin", "Room of the Treasury", "Room of the Bulls", "Room of the King"] },
        { id: 27, src: district, name: "District", description: "Shows districts and regions mentioned in the Bible.", places: ["District of Benjamin", "District of Judah", "District of Ephraim", "District of Manasseh", "District of Zebulun"] },
      ],
      selectedIcon: null,
      showFilterModal: false,
      filterSelectedBook: 'Select a book',
      bookSearch: '',
    };
  },
  computed: {
    filteredBooks() {
      return this.books.filter(book => book.toLowerCase().includes(this.bookSearch.toLowerCase()));
    },
    unselectedIcons() {
      return this.icons.filter(icon => icon.id !== this.selectedIcon?.id);
    },
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon;
    },
    deselectIcon() {
      this.selectedIcon = null;
    },
    handleFirstIconClick() {
      this.showFilterModal = true;
    },
    closeFilterModal() {
      this.showFilterModal = false;
    },
    selectBook(book) {
      this.filterSelectedBook = book;
    },
    applyFilters() {
      this.selectedBook = this.filterSelectedBook;
      this.showFilterModal = false;
    },
  },
};
</script>

<style scoped>
.container-fluid {
  height: 100%;
  width: 100%;
  background-color: #f4f0db;
  margin-top: 100px;
  padding: 0 180px 20px;
}

.title {
  color: #7a4b09;
  padding-top: 20px;
  font-size: 50px;
  font-weight: bold;
}

.book-name {
  font-size: 30px;
  color: #7a4b09;
  font-weight: 500;
  cursor: pointer;
}

.settings-icon {
  position: absolute;
  top: 200px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings-icon button {
  border: none;
  /* Remove the border */
  border-radius: 50%;
  /* Remove the corner border */
}


.map-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.map-display {
  height: 500px;
  width: 100%;
  background-color: rgb(229, 227, 223);
}

.place-types {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selected-icon-active .icon-grid {
  display: none;
}

.selected-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding-top: 48px;
}

.bottom-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: rgba(244, 240, 219, 0.8);
  border-radius: 8px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.icon-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.icon-name {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.icon-container:hover .icon-link {
  transform: scale(1.1);
}

.selected-icon-container .icon-name {
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
}

.definition-container {
  margin-top: 50px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.definition-title {
  font-size: 32px;
  color: #333333;
  margin-bottom: 20px;
  font-weight: 600;
}

.sub-header {
  font-size: 18px;
  color: #666666;
  margin-bottom: 20px;
}

.definition-text {
  font-size: 18px;
  line-height: 1.8;
  color: #444444;
  margin-bottom: 20px;
}

.occurences {
  font-size: 16px;
  color: #7a4b09;
  margin-bottom: 10px;
}

.strongs-hebrew {
  float: right;
}

.indent {
  text-indent: 20px;
  margin-bottom: 20px;
}

/* Filter Modal CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f4f0db;
  /* Use the background color from .custom-container */
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  /* Increased width for a more spacious look */
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #7a4b09;
  /* Use the color from .parallel-header h1 */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.modal-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Make dropdown container wider */
  color: #f1c053;
  background-color: #fff;
}

.modal-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* Make button take full width */
  justify-content: space-between;
  /* Space between text and caret */
  color: #7a4b09;
}

.modal-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
  width: 105%;
  /* Make dropdown menu take full width */
}

.modal-dropdown .dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-dropdown .dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.modal-dropdown .dropdown-menu li:hover {
  background-color: #f1c053;
  border-radius: 5px;
}

.modal-dropdown:hover .dropdown-menu {
  display: block;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  /* Reduced padding */
  font-size: 14px;
  /* Smaller font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-1 {
  background-color: #f1c053;
  color: #000;
}

.btn-1:hover {
  background-color: #e0b048;
}

.btn-2 {
  background-color: #ccc;
  color: #333;
}

.btn-2:hover {
  background-color: #b3b3b3;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-search {
  padding: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 2px 4px;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  background-color: transparent;
  color: #000;
}

/* Icon Information Container */
.icon-info-container {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 540px;
  position: relative;
  width: 100%;
  overflow-y: auto;
}

.icon-info-container h3 {
  color: #7a4b09;
  margin-bottom: 10px;
  font-size: 24px;
  text-align: center;
}

.icon-description {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #7a4b09;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #f1c053;
}

.selected-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.selected-icon-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: #f9f9f9;
}

.notable-places-title {
  color: #7a4b09;
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 2px solid #f1c053;
  padding-bottom: 5px;
}

.places-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
}

.places-list li {
  margin-bottom: 8px;
  color: #444444;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
</style>
