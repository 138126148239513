<template>
  <div class="login-container">
    <div class="inner-container">
      <div class="form-container">
        <h1 class="login-text">Sign In</h1>
        <form @submit.prevent="handleSubmit">
          <h1 class="welcome-text">Welcome to Wisdom eBooks Club!</h1>
          <div v-if="errors" class="error-messages">
            <p>{{ errors }}</p>
          </div>
          <ReusableFormInput label="Email Address" type="email" id="email" placeholder="example@gmail.com"
            v-model:value="email" />
          <ReusableFormInput label="Password" type="password" id="password" placeholder="Enter Password"
            v-model:value="password" />
          <router-link class="forgot-password" to="/forgot-password">Forgot Password?</router-link>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="rememberMe" v-model="rememberMe" />
            <label class="form-check-label text-white" for="rememberMe">Remember me</label>
          </div>
          <div class="button-container">
            <ReusableButton type="submit" buttonClass="btn-primary">Login</ReusableButton>
          </div>
        </form>
        <div class="alternatives">
          <p>Or login with:</p>
          <div>
            <button type="button" class="btn-google" @click="registerWithGoogle">
              <font-awesome-icon :icon="['fab', 'google']" />
            </button>
            <button type="button" class="btn-facebook" @click="registerWithFacebook">
              <font-awesome-icon :icon="['fab', 'facebook']" />
            </button>
          </div>
        </div>
        <div class="register">
          <p>Don't have an account? <router-link to="/register">Register Now</router-link></p>
        </div>
      </div>
      <div class="register-container">
        <h2>New Here?</h2>
        <p>Create an account to join our community and enjoy our eBooks collection.</p>
        <ReusableButton buttonClass="btn-secondary" @click="navigateToRegister">Register</ReusableButton>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFormInput from '../components/forms/ReusableFormInput.vue';
import ReusableButton from '../components/forms/ReusableButton.vue';
import api from '@/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';



export default {
  name: "LoginPage",
  components: {
    ReusableFormInput,
    ReusableButton
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      errors: null,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const formData = {
          email: this.email,
          password: this.password,
        };

        const response = await api.post('/login', formData);
        if (response) {
          this.notifySuccess('Login successful')
          sessionStorage.setItem('token', response.data.token)
          setTimeout(() => {
            this.$router.push('/home');
          }, 3000);
        }
      } catch (err) {
        this.notifyError('Login failed, Please check the form for errors.')
        if (err.response && err.response.status === 401) {
          this.errors = "Unauthorized: The provided credentials are incorrect.";
        } else if (err.response && err.response.data && err.response.data.message) {
          this.errors = err.response.data.message;
        } else {
          this.errors = "An unexpected error occurred. Please try again.";
        }
      }
    },
    notifySuccess(message = '') {
      const toastId = toast.success(message, {
        theme: 'colored',
        autoClose: 2000,
      })
      return toastId
    },

    notifyError(message = '') {
      const toastId = toast.error(message, {
        theme: 'colored',
        autoClose: 2000,
      })
      return toastId
    },
    navigateToRegister() {
      this.$router.push('/register');
    },
    registerWithGoogle() {
      // Handle Google registration
    },
    registerWithFacebook() {
      // Handle Facebook registration
    }
  }
};
</script>

<style scoped>
.login-container {
  background: #110f0d;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/wec-sign-in-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
}

.inner-container {
  display: flex;
  max-width: 900px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.form-container,
.register-container {
  width: 50%;
  padding: 20px;
}

.form-container {
  background-color: #110f0d;
  border-right: 1px solid #fff;
}

.register-container {
  background-image: url("../assets/images/Visitors-1-min.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
}

.register-container h2,
.register-container p {
  margin-bottom: 20px;
}

.register-container h2 {
  font-family: "Glegoo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-top: 105px;
}

.register-container p {
  font-size: 16px;
}

.text-white {
  color: #fff;
}

.terms {
  color: #fff;
}

.terms a {
  color: #f1c503;
}

.register {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}

.register a {
  color: #f1c503;
}

.login-text {
  color: #f1c503;
  font-family: "Glegoo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 7px;
}

.welcome-text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.mb-3 {
  font-size: 12px;
}

.btn-google,
.btn-facebook {
  background-color: #fff;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  max-width: 140px;
}

.btn-google .fa-google,
.btn-facebook .fa-facebook {
  color: #f1c503;
}

.btn-google:hover,
.btn-facebook:hover {
  opacity: 0.9;
}

.alternatives {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alternatives p {
  color: #f1c503;
  text-align: center;
  margin: 0;
}

.alternatives div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.forgot-password {
  display: block;
  text-align: left;
  margin-bottom: 10px;
  color: #f1c503;
  font-size: 12px;
}

.error-messages {
  background-color: #2c2c2c;
  color: #ff6b6b;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.error-messages p {
  margin: 0;
  font-size: 14px;
}
</style>
