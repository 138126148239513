<template>
  <button class="chapter-nav-button" @click="previousChapter">
    <i class="bi bi-chevron-left"></i>
  </button>
</template>

<script>
export default {
  methods: {
    previousChapter() {
      this.$emit("previous");
    },
  },
};
</script>

<style scoped>
.chapter-nav-button {
  min-width: 50px; /* Minimum width */
  min-height: 50px; /* Minimum height */
  background-color: #f1c053;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  border: none;
  padding: 10px; /* Adjust padding for button size */
}

.chapter-nav-button i {
  font-size: 16px; /* Keep the font size as desired */
}

@media (max-width: 768px) {
  .chapter-nav-button {
    min-width: 30px; /* Adjust minimum width for smaller screens */
    min-height: 30px; /* Adjust minimum height for smaller screens */
    padding: 8px; /* Adjust padding for smaller button size */
  }
}
</style>
