<template>
  <div class="filter-icon" @click="handleClick">
    <i class="bi bi-filter"></i>
  </div>
</template>

<script>
export default {
  name: "FilterIconComponent",
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.filter-icon {
  width: 40px;
  height: 40px;
  background-color: #f1c053;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}
</style>
