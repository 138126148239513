// contentData.js
export const contentData = {
  beginning: {
    title: "Beginning",
    actualWord: "רֵאשִׁית",
    relatedWords: "beginning, first, firstfruits, part, chiefest, thing, time"
  },
  God: {
    title: "God",
    actualWord: "אֱלֹהִים",
    description: "God is the supreme being and creator of the universe in the Bible. He is omnipotent, omniscient, and omnipresent."
  },
  created: {
    title: "Created",
    description: 'The "created" refers to the creation of the universe.'
  },
  heavens: {
    title: "Heavens",
    description: 'The "heavens" refer to the sky and celestial bodies created by God. This includes the stars, planets, and other astronomical entities.'
  },
  earth: {
    title: "Earth",
    description: 'The "earth" is the planet created by God, home to all living things. It is described as being created on the first day of creation.'
  },
  default: {
    title: "Word Information",
    description: "No information available."
  }
};
