<template>
  <div class="modal fade" id="bookModal" tabindex="-1" aria-labelledby="bookModalLabel" aria-hidden="true"
    ref="bookModal">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content custom-modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 image-container">
              <div class="image-and-button">
                <img :src="storage + book.book_cover" :alt="book.book_title" class="img-fluid img-thumbnail" />
                <button type="button" class="btn btn-primary read-book-btn" @click="checkSubscription()">
                  Read Book
                </button>
              </div>
            </div>
            <div class="col-md-8 text-container">
              <h5 class="modal-title" id="bookModalLabel">{{ book.book_title }}</h5>
              <p><strong>Author:</strong> {{ book.author }}</p>
              <p class="description">
                {{ book.book_description }}
              </p>
              <p class="small-font">
                <strong>Publish Date:</strong> {{ book.book_year }}
              </p>
              <p class="small-font"><strong>Publisher:</strong> {{ book.publisher }}</p>
              <div class="category">
                <p v-for="(category, index) in book.categories" :key="index" class="category-border">{{
                  category.category_name }}</p>
              </div>
              <div class="rating">
                <font-awesome-icon v-for="n in 5" :key="n" :icon="[book.userRating >= n ? 'fas' : 'far', 'star']"
                  class="fa-star" @click="setRating(book, n)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import storage from '@/constants';

export default {
  name: 'BookModal',
  data() {
    return {
      storage,
    };
  },
  props: {
    book: {
      type: Object,
      required: true,
    },
    subscription: {
      type: String,
      required: true,
    },
  },
  methods: {
    setRating(book, rating) {
      book.userRating = rating;
    },
    showModal() {
      this.$nextTick(() => {
        const modal = new bootstrap.Modal(this.$refs.bookModal);
        modal.show();
      });
    },
    hideModal() {
      const modal = new bootstrap.Modal(this.$refs.bookModal);
      modal.hide();
    },
    readBook() {
      const modal = bootstrap.Modal.getInstance(this.$refs.bookModal);
      modal.hide();
      this.$refs.bookModal.addEventListener('hidden.bs.modal', this.navigateToBook, { once: true });
    },
    checkSubscription() {
      if (this.subscription === 'Gold' || this.subscription === 'Platinum') {
        this.readBook();
      } else {
        const modal = bootstrap.Modal.getInstance(this.$refs.bookModal);
        if (modal) {
          modal.hide();
          this.$refs.bookModal.addEventListener('hidden.bs.modal', () => {
            alert('You need to subscribe to read this book');
            this.$router.push({ name: 'Subscription' });
          }, { once: true });
        } else {
          alert('Modal instance not found');
        }
      }
    },
    navigateToBook() {
      const bookDetails = {
        title: this.book.book_title,
        author: this.book.author,
        description: this.book.book_description,
        book_id: this.book.id
      };
      localStorage.setItem('bookDetails', JSON.stringify(bookDetails));
      const formattedTitle = this.book.book_title.toLowerCase().replace(/\s+/g, '-');
      this.$router.push({
        name: 'BookPdf',
        params: { id: this.book.id, title: formattedTitle },
      });
    },
  },
};
</script>

<style scoped>
.modal-content {
  text-align: center;
  color: #fff;
  background-color: rgba(28, 28, 28, 0.8);
  backdrop-filter: blur(10px);
  border: none;
  overflow: hidden;
  margin-top: -200px;
}

.modal-header {
  border-bottom: none;
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  text-align: left;
  color: #fff;
  font-size: 34px;
  margin: 0;
}

.img-fluid {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.img-thumbnail {
  max-height: 300px;
  width: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-and-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.read-book-btn {
  background-color: #f1c503;
  border: none;
  color: #110f0d;
  width: 80%;
  border-radius: 25px;
  margin-top: 10px;
}

.text-container {
  padding: 20px;
  text-align: left;
  margin-top: -30px;
}

.btn-close-white {
  filter: invert(1);
}

.description {
  font-size: 14px;
}

span {
  color: #f1c503;
}

.small-font {
  font-size: 15px;
}

.category {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12px;
}

.category-border {
  background-color: #f1c503;
  border-radius: 25px;
  padding: 5px 10px;
  color: black;
  font-weight: 400;
}

.rating {
  color: #f1c503;
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 80%;
  }
}
</style>
